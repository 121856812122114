"use strict";

(function () {
  "use strict";

  angular.module("norgrenApp").component("distributorListController", {
    controller: distributorListController,
    templateUrl: "/mkt/ln/js/app/templates/Distributor-List/mainController-template.html"
  });

  function distributorListController($scope, $rootScope, $log, $attrs, $controller, distributorStockService) {
    angular.extend(this, $controller('BaseDistributorController', {
      $scope: $scope
    }));
    var main = $scope.$ctrl;
    var defaultBimbaPostcode = "60484";
    main.postCode = $attrs.postcode || defaultBimbaPostcode;
    main.marketCode = $attrs.marketcode;
    main.cultureCode = $attrs.culturecode;
    main.countryCode = $attrs.countrycode;
    main.itemCode = $attrs.itemcode;
    main.itemImage = $attrs.itemimage;
    main.itemDescription = $attrs.itemdescription;
    main.brand = $attrs.brand;
    main.isLoading = true;
    main.data = [];
    main.currentLocation = [];
    main.currentItem = null;
    main.countries = JSON.parse($attrs.countries);
    main.selectedCountry = "United States";
    main.translationKeys = ["Why buy from a Distributor", "Request a Quote", "Your Location", "Buy From Local Distributor", "View Distributors", "Your Location", "Edit", "Buy From Local Distributor", "Tel", "Request a quote Disabled", "Why buy from a distributor Tooltip", "Distributor List Edit", "Website", "Find a Local Distributor"];
    $scope.$watch(function () {
      return distributorStockService.getStockObjects();
    }, function (newValue, oldValue) {
      main.data = distributorStockService.getStockObjects();
    });
    $scope.$watch(function () {
      return distributorStockService.getCurrentLocation();
    }, function (newValue, oldValue) {
      main.currentLocation = distributorStockService.getCurrentLocation();
    });

    main.requestAQuote = function () {
      // Set the part number txt box with the product being viewed.
      var sku = angular.element(document.querySelector(".item-sku "))[0].innerHTML;
      angular.element(document.querySelector("#request-a-quote-form .part-numbers")).val(sku);
      angular.element(document.querySelector("#request-a-quote-form .distributor-name")).val(main.currentItem.address.companyName);
      angular.element(document.querySelector("#request-a-quote-form #distributorName")).val(main.currentItem.address.companyName);
      angular.element(document.querySelector("#request-a-quote-form #distributorEmail")).val(main.currentItem.emailAddress);
      angular.element(document.querySelector("#request-a-quote-form #distributorPhone")).val(main.currentItem.phone);
      var fancyboxContent = angular.element(document.querySelector("#fancybox-content"));
      var height = angular.element(document.querySelector("#request-a-quote-form")).height() + 40;

      if (!$("#form-error").hasClass("hidden")) {
        $("#form-error").addClass("hidden");
      }

      if (!$("#form-success").hasClass("hidden")) {
        $("#form-success").addClass("hidden");
      }

      fancyboxContent.css({
        "height": height + "px"
      });
    };

    main.edit = function () {
      $rootScope.$emit("openStockLocator", {});
    };

    main.run = function () {
      main.init();
      main.loadTranslations();
    };

    main.$onInit = function () {
      main.run();
    };

    main.run();
  }
})();