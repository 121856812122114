"use strict";

(function () {
  angular.module('norgrenApp').service('Products', function ($http) {
    return InititaliseService($http);
  }).component('searchList', {
    templateUrl: '/mkt/ln/js/app/templates/searchlister-template.html',
    controller: ['$http', '$scope', 'Products', '$attrs', '$timeout', function ($http, $scope, Products, $attrs, $timeout) {
      var main = $scope.$ctrl; // Initialise variables
      //main.apiUrl = $attrs.apiurl;

      main.currentPage = 1;
      main.articlesPerPage = parseInt($attrs.articlesperpage);
      main.totalArticles = 0;
      main.totalPages = 0;
      main.searchTerm = $attrs.searchterm;
      main.url = $attrs.url;
      main.prevDisabled = true;
      main.nextDisabled = false; // Set translations

      var readMore = '';
      var pagingText = '';
      var loadingText = '';
      var noResultsText = '';
      var loadMore = '';
      main.readMore = '';
      main.pagingText = '';
      main.loadingText = '';
      main.noResultsText = '';

      main.HideOuterLoading = function () {
        $(".search-lister .search-loading.outer").css({
          "display": "none"
        });
        loadingText = $(".search-lister .search-loading.outer .loading-text").html();
        main.loadingText = loadingText;
      }; // Control loading elements


      main.HideOuterLoading();
      main.showLoading = true;

      main.getTranslations = function () {
        window.getTranslation('Read more').done(function (d) {
          readMore = d;
          $scope.$apply(function () {
            $scope.$ctrl.readMore = d;
          });
        });
        window.getTranslation('Showing results {0} to {1} of {2}').done(function (d) {
          pagingText = d;
          $scope.$apply(function () {
            var lastArticle = main.lastArticle;

            if (lastArticle > main.totalArticles) {
              lastArticle = main.totalArticles;
            }

            $scope.$ctrl.pagingText = d.format(main.firstArticle, lastArticle, main.totalArticles);
          });
        });
        window.getTranslation('No articles found').done(function (d) {
          noResultsText = d;
          $scope.$apply(function () {
            $scope.$ctrl.noResultsText = d;
          });
        });
        window.getTranslation('Load More').done(function (d) {
          loadMore = d;
          $scope.$apply(function () {
            $scope.$ctrl.loadMore = d;
          });
        });
      }; // Build paging


      main.pages = [];

      main.buildPaging = function () {
        main.pages = [];

        for (var i = 0; i < main.totalPages; i++) {
          main.pages.push(i + 1);
        }
      }; // Functions


      main.getPage = function (page) {
        main.currentPage = parseInt(page);
        main.isPrevDisabled();
        main.isNextDisabled();
        main.setArticleNumbers();
        Products.searchResults(main.searchTerm, window.gwsMc, window.gwsLc, main.firstArticle - 1, main.articlesPerPage).then(function (result) {
          main.articles = result.data.articles;
          main.totalArticles = result.data.totalArticles;
          var lastArticle = main.lastArticle;

          if (lastArticle > main.totalArticles) {
            lastArticle = main.totalArticles;
          }

          main.pagingText = pagingText.format(main.firstArticle, lastArticle, main.totalArticles);
          main.totalPages = main.totalArticles / main.articlesPerPage;
          main.buildPaging();
          main.showLoading = false;
        });
      };

      main.setArticleNumbers = function () {
        main.firstArticle = parseInt((main.currentPage - 1) * main.articlesPerPage + 1);
        main.lastArticle = parseInt(main.firstArticle + main.articlesPerPage - 1);

        if (main.lastArticle > main.totalArticles && main.totalArticles > 0) {
          main.lastArticle = main.totalArticles;
        }
      };

      main.showPager = function () {
        if ($attrs.showpager === 'true') {
          return '';
        } else {
          return ' hidden';
        }
      };

      main.nextPage = function () {
        var next = main.currentPage;

        if (next < main.totalPages) {
          next = main.currentPage + 1;
        }

        main.getPage(next);
      };

      main.prevPage = function () {
        var prev = main.currentPage;

        if (prev > 1) {
          prev = main.currentPage - 1;
        }

        main.getPage(prev);
      };

      main.isPrevDisabled = function () {
        if (main.currentPage == 1) {
          main.prevDisabled = true;
        } else {
          main.prevDisabled = false;
        }
      };

      main.isNextDisabled = function () {
        if (main.currentPage === main.totalPages) {
          main.nextDisabled = true;
        } else {
          main.nextDisabled = false;
        }
      };

      main.loadMoreItems = function () {
        var next = main.currentPage;

        if (next < main.totalPages) {
          next = main.currentPage + 1;
        }

        if (next > main.totalPages) {
          return;
        }

        main.currentPage = parseInt(next);
        main.setArticleNumbers();
        Products.searchResults(main.searchTerm, window.gwsMc, window.gwsLc, main.firstArticle - 1, main.articlesPerPage).then(function (result) {
          main.articles = main.articles.concat(result.data.articles);
          main.totalArticles = result.data.totalArticles;
          var lastArticle = main.lastArticle;

          if (lastArticle > main.totalArticles) {
            lastArticle = main.totalArticles;
          }

          main.pagingText = pagingText.format(main.firstArticle, lastArticle, main.totalArticles);
          main.totalPages = main.totalArticles / main.articlesPerPage;
          main.buildPaging();
        });
      }; // Initialise first results


      main.getPage(main.currentPage);
      main.getTranslations();
    }]
  });

  function InititaliseService($http) {
    var service = {};

    service.searchResults = function (term, market, culture, start, take) {
      return $http.get('/' + market.toLowerCase() + '/' + culture.toLowerCase() + "/service/umbracosearch/ajax.aspx?term=" + term + "&start=" + start + "&take=" + take);
    };

    return service;
  }
})();