"use strict";

(function () {
  function libraryTagsService() {
    var service = this;

    service.getTags = function (data, translations) {
      var tags = [];
      data.categories.forEach(function (category) {
        category.items.forEach(function (item) {
          tags = tags.concat(item.tags);
        });
      });
      var nonDuplicateTags = tags.filter(function (item, index) {
        return tags.indexOf(item) === index;
      });
      var tagsObj = nonDuplicateTags.map(function (item) {
        return {
          text: item,
          value: item,
          selected: false,
          count: 0
        };
      });
      tagsObj.forEach(function (tagObj) {
        data.categories.forEach(function (category) {
          category.items.forEach(function (item) {
            if (item.tags.some(function (tag) {
              return tag === tagObj.value;
            })) {
              tagObj.count++;
            }
          });
        });
      });
      var tagAll = tagsObj.find(function (tagObj) {
        return tagObj.value === "All";
      });

      if (!tagAll) {
        tagsObj.unshift({
          text: translations["All"],
          value: "All",
          selected: true,
          count: countItems(data)
        });
      } else {
        tagAll.count = countItems(data);
      }

      return tagsObj;
    };

    service.filterBySelectedTag = function (selectedTag, data) {
      //current page will be reset to 1 every time 
      var visibleItemsCount = 0; //when not using pagiantion this should be the total number of articles

      var itemsPerPage = data.itemsPerPage;

      if (selectedTag.value !== "All") {
        data.categories.forEach(function (category) {
          category.items.forEach(function (item) {
            if (visibleItemsCount !== itemsPerPage) {
              item.visible = item.tags.some(function (tag) {
                return tag === selectedTag.value;
              });
              visibleItemsCount += item.visible ? 1 : 0;
            } else {
              item.visible = false;
            }
          });
          category.visible = category.items.some(function (item) {
            return item.visible;
          });
        });
      } else {
        data.categories.forEach(function (category) {
          category.items.forEach(function (item) {
            if (visibleItemsCount !== itemsPerPage) {
              item.visible = true;
              visibleItemsCount++;
            } else {
              item.visible = false;
            }
          });
          category.visible = category.items.some(function (item) {
            return item.visible;
          });
        });
      }

      return data;
    };

    service.setSelectedTag = function (tags, selectedTag) {
      tags.forEach(function (tag) {
        return tag.selected = false;
      });
      var tag = tags.find(function (tag) {
        return tag.value === selectedTag.value;
      });
      tag.selected = true;
      return tags;
    };

    function countItems(data) {
      var amount = 0;
      data.categories.forEach(function (category) {
        category.items.forEach(function (item) {
          amount++;
        });
      });
      return amount;
    }
  }

  angular.module('norgrenApp').service("libraryTagsService", libraryTagsService);
})();