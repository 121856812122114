"use strict";

(function () {
  function libraryVideo() {}

  angular.module('norgrenApp').component('libraryVideo', {
    templateUrl: '/mkt/ln/js/app/templates/ContentItemsLister/library-video-template.html',
    controller: libraryVideo,
    bindings: {
      item: '='
    }
  });
})();