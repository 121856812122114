"use strict";

(function () {
  try {
    var app = angular.module('norgrenApp');
  } catch (err) {
    console.log("APP fetch failed: " + err);
  }

  function CreateTactonFactories() {
    ///
    /// Factories
    ///
    this.tactonRootObjectFactory = function (tactonStep, tactonResponse) {
      function tactonRootObject(configState, steps, response, translations, currentVersion) {
        this.configState = configState;
        this.steps = steps;
        this.response = response;
        this.translations = translations;
        this.currentVersion = currentVersion;
      }

      function buildSteps(tactonStep, data, activeOnly) {
        var steps = [];

        for (var i = 0; i < data.length; i++) {
          if (activeOnly) {
            if (data[i].current) {
              steps.push(tactonStep.build(data[i]));
            }
          } else {
            steps.push(tactonStep.build(data[i]));
          }
        }

        return steps;
      }

      tactonRootObject.build = function (data) {
        if (data === null) {
          return;
        }

        var steps = buildSteps(tactonStep, data.steps, true);
        var response = null;

        if (data.response !== null) {
          response = tactonResponse.build(data.response);
        }

        return new tactonRootObject(data.configState, steps, response, data.translations, data.currentVersion);
      };

      return tactonRootObject;
    };

    this.tactonResponseFactory = function (tactonChanged) {
      function tactonResponse(status, changed) {
        this.status = status;
        this.changed = changed;
      }

      tactonResponse.build = function (data) {
        if (data === null) {
          return;
        }

        var changed = buildChanged(tactonChanged, data.changed);
        return new tactonResponse(data.status, changed);
      };

      return tactonResponse;
    };

    this.tactonChangedFactory = function () {
      function tactonChanged(name, description, oldValue, oldValueDescription, newValue, newValueDescription) {
        this.name = name;
        this.description = description;
        this.oldValue = oldValue;
        this.oldValueDescription = oldValueDescription;
        this.newValue = newValue;
        this.newValueDescription = newValueDescription;
      }

      tactonChanged.build = function (data) {
        if (data === null) {
          return;
        }

        return new tactonChanged(data.name, data.description, data.oldValue, data.oldValueDescription, data.newValue, data.newValueDescription);
      };

      return tactonChanged;
    };

    this.tactonStepFactory = function (tactonRootGroup) {
      function tactonStep(name, description, current, available, rootGroup, motionProfileMinStepCount, motionProfileMaxStepCount, cssClass, cadenasValue, cadenasProjectPath, carriagePositionValue, datasheetEnFileName, datasheetDeFileName) {
        this.name = name;
        this.description = description;
        this.current = current;
        this.available = available;
        this.rootGroup = rootGroup;
        this.motionProfileMaxStepCount = motionProfileMaxStepCount;
        this.motionProfileMinStepCount = motionProfileMinStepCount;
        this.cssClass = cssClass;
        this.cadenasValue = cadenasValue;
        this.cadenasProjectPath = cadenasProjectPath;
        this.carriagePositionValue = carriagePositionValue;
        this.datasheetEnFileName = datasheetEnFileName;
        this.datasheetDeFileName = datasheetDeFileName;
      }

      tactonStep.build = function (data) {
        if (data === null) {
          return;
        }

        return new tactonStep(data.name, data.description, data.current, data.available, tactonRootGroup.build(data.rootGroup), data.MotionProfileMinStepCount, data.MotionProfileMaxStepCount, data.CssClass, data.CadenasValue, data.CadenasProjectPath, data.CarriagePositionValue, data.DatasheetEnFileName, data.DatasheetDeFileName);
      };

      return tactonStep;
    };

    this.tactonRootGroupFactory = function (tactonMember) {
      function tactonRootGroup(name, description, hasVisibleParameters, members) {
        this.name = name;
        this.description = description;
        this.hasVisibleParameters = hasVisibleParameters;
        this.members = members;
      }

      tactonRootGroup.build = function (data) {
        if (data === null) {
          return;
        }

        var members = buildMembers(tactonMember, data.members);
        return new tactonRootGroup(data.name, data.description, data.hasVisibleParameters, members);
      };

      return tactonRootGroup;
    };

    this.tactonMemberFactory = function (tactonProperties, tactonDomain) {
      function tactonMember(isGroup, isParameter, name, descrption, value, valueDescription, committed, hasVisibleParameters, properties, domain, members, ignore, renderDifferently, nestedLevel, parentMemberName, alwaysCommitted) {
        this.isGroup = isGroup;
        this.isParameter = isParameter;
        this.name = name;
        this.description = descrption;
        this.value = value;
        this.valueDescription = valueDescription;
        this.committed = committed;
        this.hasVisibleParameters = hasVisibleParameters;
        this.properties = properties;
        this.domain = domain;
        this.members = members;
        this.ignore = ignore;
        this.renderDifferently = renderDifferently;
        this.nestedLevel = nestedLevel;
        this.parentMemberName = parentMemberName;
        this.alwaysCommitted = alwaysCommitted;
      }

      tactonMember.build = function (data) {
        if (data === null) {
          return;
        }

        var members = buildMembers(tactonMember, data.members);
        return new tactonMember(data.isGroup, data.isParameter, data.name, data.description, data.value, data.valueDescription, data.committed, data.hasVisibleParameters, tactonProperties.build(data.properties), tactonDomain.build(data.domain), members, data.Ignore, data.RenderDifferently, data.NestedLevel, data.ParentMemberName, data.alwaysCommitted);
      };

      return tactonMember;
    };

    this.tactonPropertiesFactory = function () {
      function tactonProperties(hidden, info_group, guitype, diagramData, tc_field_picture, hide_from_leadgen, renderDifferently, displayChildMembersInline, childMemberCount, tc_unit, hiddenAsBool, infoGroupAsBool, hideFromLeadGenAsBool) {
        this.hidden = hidden;
        this.info_group = info_group;
        this.guitype = guitype;
        this.diagramData = diagramData;
        this.tc_field_picture = tc_field_picture;
        this.hide_from_leadgen = hide_from_leadgen;
        this.renderDifferently = renderDifferently;
        this.displayChildMembersInline = displayChildMembersInline;
        this.childMemberCount = parseInt(childMemberCount, 10);
        this.tc_unit = tc_unit;
        this.hiddenAsBool = hiddenAsBool;
        this.infoGroupAsBool = infoGroupAsBool;
        this.hideFromLeadGenAsBool = hideFromLeadGenAsBool;
      }

      tactonProperties.build = function (data) {
        if (data === null) {
          return;
        }

        return new tactonProperties(data.hidden, data.info_group, data.guitype, data.diagramData, data.tc_field_picture, data.hide_from_leadgen, data.renderDifferently, data.displayChildMembersInline, data.childMemberCount, data.tc_unit, data.HiddenAsBool, data.InfoGroupAsBool, data.HideFromLeadGenAsBool);
      };

      return tactonProperties;
    };

    this.tactonDomainFactory = function (tactonElement) {
      function tactonDomain(name, max, min, elements) {
        this.name = name;
        this.max = max;
        this.min = min;
        this.elements = elements;
      }

      tactonDomain.build = function (data) {
        if (data === null) {
          return;
        }

        var elements = buildElements(tactonElement, data.elements);
        return new tactonDomain(data.name, data.max, data.min, elements);
      };

      return tactonDomain;
    };

    this.tactonElementFactory = function () {
      function tactonElement(name, description, state, selected) {
        this.name = name;
        this.description = description;
        this.state = state;
        this.selected = selected;
      }

      tactonElement.build = function (data) {
        if (data === null) {
          return;
        }

        return new tactonElement(data.name, data.description, data.state, data.selected);
      };

      return tactonElement;
    };

    this.tactonBomRootObjectFactory = function (tactonBom) {
      function tactonBomRootObject(status, bom, translations, currentVersion) {
        this.status = status;
        this.bom = bom;
        this.translations = translations;
        this.currentVersion = currentVersion;
      }

      tactonBomRootObject.build = function (data) {
        if (data === null) {
          return;
        }

        var subItems = buildSubItems(tactonBom, data.bom);
        return new tactonBomRootObject(data.status, subItems, data.translations, data.currentVersion);
      };

      return tactonBomRootObject;
    };

    this.tactonBomFactory = function (tactonAttribute) {
      function tactonBom(name, descrption, qty, attributes, subItems, ignore) {
        this.name = name;
        this.description = descrption;
        this.qty = qty;
        this.attributes = attributes;
        this.subItems = subItems;
        this.ignore = ignore;
      }

      tactonBom.build = function (data) {
        if (data === null) {
          return;
        }

        var subItems = buildSubItems(tactonBom, data.subItems);
        return new tactonBom(data.name, data.description, data.qty, tactonAttribute.build(data.attributes), subItems, data.Ignore);
      };

      return tactonBom;
    };

    this.tactonAttributeFactory = function () {
      function tactonAttribute(hideInPricing, instance, componentCode, totalQty, level, uniqueComponentId, fbpMultiplier, netPrice, fbpOperator, fbpResultType, isLeaf, listPrice, tc_component_picture, description_en, isEto, hideFromLeadGen, description_de, allowZeroPrice, longCode, protocol_code) {
        this.hideInPricing = hideInPricing;
        this.instance = instance;
        this.componentCode = componentCode;
        this.totalQty = totalQty;
        this.level = level;
        this.uniqueComponentId = uniqueComponentId;
        this.fbpMultiplier = fbpMultiplier;
        this.netPrice = netPrice;
        this.fbpOperator = fbpOperator;
        this.fbpResultType = fbpResultType;
        this.isLeaf = isLeaf;
        this.listPrice = listPrice;
        this.tc_component_picture = tc_component_picture;
        this.description_en = description_en;
        this.isEto = isEto;
        this.hideFromLeadGen = hideFromLeadGen;
        this.description_de = description_de;
        this.longCode = longCode;
        this.allowZeroPrice = allowZeroPrice;
        this.protocol_code = protocol_code;
      }

      tactonAttribute.build = function (data) {
        if (data === null) {
          return;
        }

        return new tactonAttribute(data.hideInPricingAsBool, data.instance, data.componentCode, data.totalQty, data.level, data.uniqueComponentId, data.fbpMultiplier, data.netPrice, data.fbpOperator, data.fbpResultType, data.isLeaf, data.listPrice, data.tc_component_picture, data.description_en, data.isEto, data.hideFromLeadGenAsBool, data.description_de, data.allowZeroPriceAsBool, data.longCode, data.protocol_code);
      };

      return tactonAttribute;
    }; ///
    /// END Factories
    ///


    function buildMembers(tactonMember, data) {
      var members = [];

      for (var i = 0; i < data.length; i++) {
        members.push(tactonMember.build(data[i]));
      }

      return members;
    }

    function buildElements(tactonElement, data) {
      var elements = [];

      for (var i = 0; i < data.length; i++) {
        elements.push(tactonElement.build(data[i]));
      }

      return elements;
    }

    function buildSubItems(tactonBom, data) {
      var subItems = [];

      for (var i = 0; i < data.length; i++) {
        subItems.push(tactonBom.build(data[i]));
      }

      return subItems;
    }

    function buildChanged(tactonChanged, data) {
      var changed = [];

      for (var i = 0; i < data.length; i++) {
        changed.push(tactonChanged.build(data[i]));
      }

      return changed;
    }
  }

  var factories = new CreateTactonFactories();
  app.factory('tactonRootObject', ['tactonStep', 'tactonResponse', factories.tactonRootObjectFactory]);
  app.factory('tactonResponse', ['tactonChanged', factories.tactonResponseFactory]);
  app.factory('tactonChanged', factories.tactonChangedFactory);
  app.factory('tactonStep', ['tactonRootGroup', factories.tactonStepFactory]);
  app.factory('tactonRootGroup', ['tactonMember', factories.tactonRootGroupFactory]);
  app.factory('tactonMember', ['tactonProperties', 'tactonDomain', factories.tactonMemberFactory]);
  app.factory('tactonProperties', factories.tactonPropertiesFactory);
  app.factory('tactonDomain', ['tactonElement', factories.tactonDomainFactory]);
  app.factory('tactonElement', factories.tactonElementFactory);
  app.factory('tactonBomRootObject', factories.tactonBomRootObjectFactory);
  app.factory('tactonBom', factories.tactonBomFactory);
  app.factory('tactonAttribute', factories.tactonAttributeFactory);
})(); //# sourceURL=tacton.factories.js