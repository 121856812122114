"use strict";

(function () {
  function pagingComponent($scope, $sce) {
    var main = $scope.$ctrl;
    main.articlesPerPage = 0;
    main.pages = [];
    main.currentPageIndex = 0;
    main.prevDisabled = true;
    main.nextDisabled = false;
    main.pagingText = "";
    main.pagingTextUnformated = "";

    main.hideFrom = function () {
      main.setPagingText();
      return Math.ceil(main.totalArticles / main.articlesPerPage);
    };

    main.prevPage = function () {
      if (main.currentPage === 0) {
        return;
      }

      main.currentPageIndex -= 1;
      main.pageClickCallback(main.currentPageIndex);
      main.handlePagingFinished();
    };

    main.nextPage = function () {
      if (main.currentPageIndex + 1 > main.pages.length) {
        return;
      }

      main.currentPageIndex += 1;
      main.pageClickCallback(main.currentPageIndex);
      main.handlePagingFinished();
    };

    main.getPage = function (page) {
      //Index start at 0. Paging shows at 1.
      main.currentPageIndex = page - 1;
      main.pageClickCallback(main.currentPageIndex);
      main.handlePagingFinished();
      main.setPagingText();
    };

    main.handlePagingFinished = function () {
      main.currentPage = main.currentPageIndex + 1;
      main.nextDisabled = main.currentPageIndex >= main.pages.length - 1;
      main.prevDisabled = main.currentPageIndex === 0;
      main.setPagingText();
    };

    main.setPagingText = function () {
      var currentMaxItem = main.currentPage * main.articlesPerPage;

      if (currentMaxItem > main.totalArticles) {
        currentMaxItem = main.totalArticles;
      }

      main.pagingText = main.pagingTextUnformated.format(main.currentPageIndex * main.itemsPerPage + 1, currentMaxItem, main.totalArticles);
    };

    main.init = function () {
      main.articlesPerPage = main.itemsPerPage;
      main.pagingTextUnformated = $sce.valueOf(main.translations['Showing results {0} to {1} of {2}']);
      main.setPagingText();

      for (var i = 0; i < Math.ceil(main.totalArticles / main.articlesPerPage); i++) {
        main.pages.push(i + 1);
      }
    };

    main.init();
  }

  angular.module('norgrenApp').component('pagingComponent', {
    templateUrl: '/mkt/ln/js/app/templates/paging-template.html',
    controller: pagingComponent,
    bindings: {
      translations: '=',
      data: '=',
      itemsPerPage: "=",
      pageClickCallback: "<",
      currentPage: '=',
      totalArticles: '='
    }
  });
})(); //# sourceURL=paging.component.js