"use strict";

(function () {
  angular.module('norgrenApp').service('Articles', function ($http) {
    return InititaliseService($http);
  }).component('newsList', {
    templateUrl: '/mkt/ln/js/app/templates/newslister-template.html',
    controller: ['$http', '$sce', '$scope', 'Articles', '$attrs', '$timeout', function ($http, $sce, $scope, Articles, $attrs, $timeout) {
      var main = $scope.$ctrl; // Initialise variables
      //main.apiUrl = $attrs.apiurl;

      main.currentPage = 1;
      main.articlesPerPage = parseInt($attrs.articlesperpage);
      main.totalArticles = parseInt($attrs.totalarticles);
      main.totalPages = parseInt($attrs.totalpages);
      main.url = $attrs.url;
      main.prevDisabled = true;
      main.nextDisabled = false; // Set translations

      var readMore = '';
      var pagingText = '';
      var noResultsText = '';
      main.readMore = '';
      main.pagingText = '';
      main.noResultsText = '';

      main.HideOuterLoading = function () {
        $(".news-lister .news-loading.outer").css({
          "display": "none"
        });
        var loadingText = $(".news-lister .news-loading.outer .loading-text").html();
        main.loadingText = loadingText;
      }; // Control loading elements


      main.HideOuterLoading();
      main.showLoading = true;

      main.getTranslations = function () {
        window.getTranslation('Read more').done(function (d) {
          readMore = d;
          $scope.$apply(function () {
            $scope.$ctrl.readMore = d;
          });
        });
        window.getTranslation('Showing results {0} to {1} of {2}').done(function (d) {
          pagingText = d;
          $scope.$apply(function () {
            var lastArticle = main.lastArticle;

            if (lastArticle > main.totalArticles) {
              lastArticle = main.totalArticles;
            }

            $scope.$ctrl.pagingText = d.format(main.firstArticle, lastArticle, main.totalArticles);
          });
        });
        window.getTranslation('No articles found').done(function (d) {
          noResultsText = d;
          $scope.$apply(function () {
            $scope.$ctrl.noResultsText = d;
          });
        });
      }; // Build paging


      main.pages = [];

      for (var i = 0; i < main.totalPages; i++) {
        main.pages.push(i + 1);
      } // Functions


      main.getPage = function (page) {
        main.currentPage = parseInt(page);
        main.isPrevDisabled();
        main.isNextDisabled();
        main.setArticleNumbers();
        var lastArticle = main.lastArticle;

        if (lastArticle > main.totalArticles) {
          lastArticle = main.totalArticles;
        }

        main.pagingText = pagingText.format(main.firstArticle, lastArticle, main.totalArticles);
        Articles.getArticles(main.url, main.firstArticle - 1, main.articlesPerPage).then(function (result) {
          main.articles = result.data;
          main.showLoading = false;
        });
      };

      main.setArticleNumbers = function () {
        main.firstArticle = parseInt((main.currentPage - 1) * main.articlesPerPage + 1);
        main.lastArticle = parseInt(main.firstArticle + main.articlesPerPage - 1);

        if (main.lastArticle > main.totalArticles) {
          main.lastArticle = main.totalArticles;
        }
      };

      main.showPager = function () {
        if ($attrs.showpager === 'true') {
          return '';
        } else {
          return ' hidden';
        }
      };

      main.nextPage = function () {
        var next = main.currentPage;

        if (next < main.totalPages) {
          next = main.currentPage + 1;
        }

        main.getPage(next);
      };

      main.prevPage = function () {
        var prev = main.currentPage;

        if (prev > 1) {
          prev = main.currentPage - 1;
        }

        main.getPage(prev);
      };

      main.isPrevDisabled = function () {
        if (main.currentPage == 1) {
          main.prevDisabled = true;
        } else {
          main.prevDisabled = false;
        }
      };

      main.isNextDisabled = function () {
        if (main.currentPage === main.totalPages) {
          main.nextDisabled = true;
        } else {
          main.nextDisabled = false;
        }
      };

      main.sanitize = function (value) {
        return $sce.trustAsHtml(value);
      }; // Initialise first results
      //main.hidden = main.showPager();


      main.getPage(main.currentPage);
      main.getTranslations();
    }]
  });

  function InititaliseService($http) {
    var service = {};

    service.getArticles = function (path, start, take) {
      if (!path.toLowerCase().startsWith('/' + window.gwsMc.toLowerCase())) {
        return $http.get(window.appPath + '/service/newsarticles/ajax.aspx?newslisterpath=' + '/' + window.gwsMc + path + '&order=0&start=' + start + '&take=' + take);
      } else {
        return $http.get(window.appPath + '/service/newsarticles/ajax.aspx?newslisterpath=' + path + '&order=0&start=' + start + '&take=' + take);
      }
    };

    return service;
  }

  ;
})();