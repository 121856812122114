"use strict";

(function () {
  "use strict";

  angular.module("norgrenApp").controller("BaseNorgrenController", function ($scope, $q, translationsService) {
    var main = $scope.$ctrl;
    main.translations = [];
    main.translationKeys = undefined;

    main.loadTranslations = function () {
      var deferred = $q.defer();
      translationsService.loadTranslations(main.translations, main.translationKeys).then(function (data) {
        main.translations = data;
        deferred.resolve(main.translations);
      });
      return deferred.promise;
    };
  });
})();