"use strict";

(function () {
  function librarySearchService() {
    var service = this;

    service.doSearch = function (data, searchTerm) {
      var searchFuncs = [searchDonwloadItem, searchPanelItem, searchVideoItem];
      data.categories.forEach(function (category) {
        category.items.forEach(function (item) {
          item.visible = searchTerm ? searchFuncs[item.itemType](searchTerm, item) : true;
        });
        category.visible = category.items.some(function (item) {
          return item.visible;
        });
      });
      return data;
    };

    function searchDonwloadItem(searchTerm, item) {
      return item.link.indexOf(searchTerm) > 0 || item.title.indexOf(searchTerm) > 0;
    }

    function searchVideoItem(searchTerm, item) {
      return item.link.indexOf(searchTerm) > 0 || item.date.indexOf(searchTerm) > 0 || item.title.indexOf(searchTerm) > 0 || item.subTitle.indexOf(searchTerm) > 0 || item.runtime.indexOf(searchTerm) > 0;
    }

    function searchPanelItem(searchTerm, item) {
      return item.html.indexOf(searchTerm) > 0;
    }
  }

  angular.module('norgrenApp').service("librarySearchService", librarySearchService);
})();