"use strict";

(function () {
  "use strict";

  angular.module('norgrenApp').component('contentList', {
    templateUrl: '/mkt/ln/js/app/templates/ContentItemsLister/contentItemsLister-template.html',
    controller: function controller($http, $scope, libraryAjaxService, $attrs, $timeout, $controller, LibraryContentModelFactory, libraryTagsService, librarySearchService, $sce) {
      angular.extend(this, $controller('BaseNorgrenController', {
        $scope: $scope
      }));
      var main = $scope.$ctrl; // Initialise variables
      //main.apiUrl = $attrs.apiurl;

      main.marketCode = window.gwsMc;
      main.url = $attrs.url;
      main.data = {}; // Set translations

      main.translationKeys = ['Read more', 'Showing results {0} to {1} of {2}', 'No articles found', 'Sort By', 'Newest', 'Oldest', 'Categories', 'All', 'Learn More'];
      main.translations = [];
      main.sortByOptions = [];
      main.tags = [];
      main.sortByPlaceholder = "";
      main.isSticky = false;

      var flattenCategoryItems = function flattenCategoryItems(categories) {
        //get items references, not values
        var items = [];
        categories.forEach(function (item) {
          items = items.concat(item.items);
        });
        return items;
      };

      var countItems = function countItems(data) {
        if (!data) return 0;
        var items = 0;
        data.forEach(function (category) {
          items += category.items.length;
        });
        return items;
      };

      var getFilteredCategories = function getFilteredCategories(categories, selectedTag) {
        if (!categories) return undefined;
        var filteredCategories = JSON.parse(JSON.stringify(categories));
        if (!selectedTag || selectedTag.value === "All") return filteredCategories;
        filteredCategories.forEach(function (category) {
          category.items = category.items.filter(function (item) {
            return item.tags.some(function (tag) {
              return tag === selectedTag.value;
            });
          });
        });
        filteredCategories = filteredCategories.filter(function (category) {
          return category.items.length > 0;
        });
        return filteredCategories;
      };

      main.init = function () {
        // Control loading elements
        if (!main.url.startsWith("/" + main.marketCode.toLowerCase())) {
          main.url = "/" + main.marketCode.toLowerCase() + main.url;
        }

        main.pagingCurrentPage = 1;
        main.HideOuterLoading();
        main.showLoading = true;
        main.getTranslations();
      };

      main.HideOuterLoading = function () {
        $(".news-loading").css({
          "display": "none"
        });
        var loadingText = $(".news-loading .loading-text").html();
        main.loadingText = loadingText;
      };

      main.getTranslations = function () {
        main.loadTranslations(main.translations, main.translationKeys).then(function (data) {
          main.pagingTextUnformated = main.translations['Showing results {0} to {1} of {2}'];
          main.sortByOptions.push({
            text: main.translations["Newest"],
            id: 0
          });
          main.sortByOptions.push({
            text: main.translations["Oldest"],
            id: 1
          });
          main.sortByPlaceholder = $sce.valueOf(main.translations["Sort By"]);
          main.getData();
        });
      };

      main.getData = function () {
        libraryAjaxService.getItems(main.url, main.marketCode, "").then(function (data) {
          main.data = LibraryContentModelFactory.build(JSON.parse(data.data));
          main.tags = libraryTagsService.getTags(main.data, main.translations);
          main.filteredData = getFilteredCategories(main.data.categories, main.tags.find(function (t) {
            return t.selected === true;
          }));
          main.totalArticles = main.data.usePagination ? countItems(main.filteredData) : countItems(main.data.categories);
          main.pagingCurrentPage = 1;
          main.showLoading = false;
        });
      };

      main.selectTagsCallback = function (tag) {
        if (tag.selected) return;
        main.tags = libraryTagsService.setSelectedTag(main.tags, tag);
        main.data = libraryTagsService.filterBySelectedTag(tag, main.data);
        main.filteredData = getFilteredCategories(main.data.categories, main.tags.find(function (t) {
          return t.selected === true;
        }));
        main.totalArticles = main.data.usePagination ? countItems(main.filteredData) : countItems(main.data.categories);
        main.pagingCurrentPage = 1;
      };

      main.searchCallback = function (searchTerm) {
        main.data = librarySearchService.doSearch(main.data, searchTerm);
        main.tags = libraryTagsService.getTags(main.data, main.translations);
        main.filteredData = getFilteredCategories(main.data.categories, main.tags.find(function (t) {
          return t.selected === true;
        }));
        main.totalArticles = main.data.usePagination ? countItems(main.filteredData) : countItems(main.data.categories);
        main.pagingCurrentPage = 1;
      };

      main.pageClickCallback = function (page) {
        //gets item references
        var items = flattenCategoryItems(main.data.categories);
        var selectedTag = main.tags.find(function (t) {
          return t.selected === true;
        });
        var tagItems = [];
        items.forEach(function (item) {
          item.visible = false;

          if (item.tags.some(function (tag) {
            return tag === selectedTag.value;
          }) || selectedTag.value === 'All') {
            tagItems.push(item); //need comparison a && comparison b otherwise returns true

            item.visible = page * main.data.itemsPerPage < tagItems.length && tagItems.length <= (page + 1) * main.data.itemsPerPage;
          }
        });
        main.data.categories.forEach(function (category) {
          category.visible = category.items.some(function (item) {
            return item.visible;
          });
        });
      };

      main.init();
    }
  });
})();