"use strict";

(function () {
  var app = angular.module('norgrenApp');
  app.value('$routerRootComponent', 'mainapp');
  app.service('frlAjaxService', function ($http) {
    return InititaliseService($http);
  });
  app.component('mainController', {
    controller: ['$http', '$sce', '$scope', 'frlAjaxService', '$attrs', '$rootRouter', '$window', '$q', function ($http, $sce, $scope, frlAjaxService, $attrs, $rootRouter, $window, $q) {
      initialiseDataLayerTracking();
      var self = this;
      self.Units = [];
      self.ModelJS = InitModelJS();
      frlAjaxService.GetSeriesImages().then(function (result) {
        self.ModelJS.EightyTwoSeriesImage = result.data.EightyTwoSeriesImage;
        self.ModelJS.EightyFourSeriesImage = result.data.EightyFourSeriesImage;
        self.ModelJS.HEightyFourSeriesImage = result.data.HEightyFourSeriesImage; // The user has navigated from the landing page if there's a series id in the url.

        var seriesId = -1;

        if ($("#seriesId").html().length > 0) {
          seriesId = $("#seriesId").html();

          if (seriesId > 0) {
            frlAjaxService.GetUnits(seriesId).then(function (result) {
              self.ModelJS.ShowSeries = false;
              self.ModelJS.Series = result.data.Series;
              self.ModelJS.UnitsList = result.data.UnitsList;
              self.ModelJS.ShowUnits = result.data.ShowUnits;
              self.ModelJS.SelectedIndex = result.data.SelectedIndex;
              self.ModelJS.Translations = result.data.Translations;
              self.ModelJS.Flow = result.data.Flow;
              self.ModelJS.SelectedUnit = undefined;
              self.ModelJS.PortSize = $("#portSize").html();

              if (result.data.UnitAttributesList != null) {
                self.ModelJS.UnitAttributesList = result.data.UnitAttributesList;
              }
            });
          }
        }

        var partNumber = '';
        var userConfigurationId = $("#userConfigurationId").html();

        if (userConfigurationId > 0 || $("#partNumber").html().length > 0) {
          partNumber = $("#partNumber").html();
          frlAjaxService.LoadSavedConfiguration(userConfigurationId, partNumber, self.ModelJS).then(function (result) {
            self.ModelJS = result.data;
            self.ModelJS.MarketCode = window.gwsMc;
            self.ModelJS.LanguageCode = window.gwsLc;
            self.ModelJS.AllowAnonPrices = window.gwsShp;
            self.ModelJS.CadURL = $sce.trustAsResourceUrl(getCadUrl(self.ModelJS.Series.seriesName, self.ModelJS.CadPnInput, self.ModelJS.Flow, self.ModelJS.ShowConfiguration));
            if (self.ModelJS.UnitAttributesList.length == 1) self.ModelJS.ShowRemoveUnitButton = true;
            setCadFancyBoxDimensions(); //Save the configuration owner, as this may have been an anonymous config which now needs to be associated with an account.

            $scope.updateConfigurationOwner();
          });
        }
      });
      frlAjaxService.GetClamps(self.ModelJS.LanguageCode).then(function (response) {
        self.ModelJS.ClampsList = response.data;
      });
      $scope.$on('event_GetSeriesImages', function (event, seriesId) {
        frlAjaxService.GetSeriesImages(seriesId).then(function (result) {
          return result.data;
        });
      });
      $scope.$on('event_GetUnits', function (event, seriesId) {
        showAjaxLoader();
        frlAjaxService.GetUnits(seriesId).then(function (result) {
          self.ModelJS.ShowSeries = false;
          self.ModelJS.Series = result.data.Series;
          self.ModelJS.UnitsList = result.data.UnitsList;
          self.ModelJS.ShowUnits = result.data.ShowUnits;
          self.ModelJS.SelectedIndex = result.data.SelectedIndex;
          self.ModelJS.Translations = result.data.Translations;
          self.ModelJS.Flow = result.data.Flow;
          self.ModelJS.SelectedUnit = undefined;
          self.ModelJS.PortSize = $("#portSize").html();

          if (result.data.UnitAttributesList != null) {
            self.ModelJS.UnitAttributesList = result.data.UnitAttributesList;
          }

          hideAjaxLoader();
        });
        window.scrollTo(0, 0);
        hideAjaxLoader();
      });
      $scope.$on('event_UnitSelected', function (event, unit) {
        showAjaxLoader();
        var model = clearAttributesList(self.ModelJS);
        self.ModelJS.SelectedUnit = unit;
        frlAjaxService.UnitSelected(unit.UnitID, unit.AttributeValue, model).then(function (result) {
          self.ModelJS.UnitAttributesList = result.data.UnitAttributesList;
          self.ModelJS.UnitAttributesList.forEach(function (unitAttribute) {
            unitAttribute.attributes.forEach(function (attribute) {
              if (attribute.AttributeList.length === 1) {
                attribute.SelectedAttributeValue = attribute.AttributeList[0].attributeValueID;
              }
            });
          });
          self.ModelJS.ShowUnits = result.data.ShowUnits;
          self.ModelJS.ShowUnitData = result.data.ShowUnitData;
          self.ModelJS.CurrentUnitId = result.data.CurrentUnitId;
          self.ModelJS.ConfigCode = result.data.ConfigCode;
          self.ModelJS.ShowAddUnitButton = result.data.ShowAddUnitButton;
          self.ModelJS.Flow = result.data.Flow;
          self.ModelJS.ShowConfigurationButton = result.data.ShowConfigurationButton;
          self.ModelJS.CadPnInput = result.data.CadPnInput;
          self.ModelJS.Translations = result.data.Translations;
          self.ModelJS.ShowRemoveUnitButton = true;
          self.ModelJS.PipeAdapter = 0;
          self.ModelJS.PipeAdapterStream = 0;
          self.ModelJS.CadURL = $sce.trustAsResourceUrl(getCadUrl(self.ModelJS.Series.seriesName, self.ModelJS.CadPnInput, self.ModelJS.Flow, self.ModelJS.ShowConfiguration));
          setTimeout(function () {
            $('.match-height').css("height", "");
            window.matchColumnHeights();
          }, 0);
          setCadFancyBoxDimensions();
          updateDataLayer({
            configuratorStep: self.ModelJS.SelectedIndex + 1
          });
          window.scrollTo(0, 0);
          hideAjaxLoader();
        });
      });
      $scope.$on('event_DiscardConfiguration', function (event, data) {
        var series = self.ModelJS.Series;
        self.ModelJS = InitModelJS();
        self.ModelJS.Series = series;
        $scope.$emit('event_GetUnits', series.seriesID);
      });
      $scope.$on('event_AddNextUnit', function (event, data) {
        var isInvalid = ValidateModel();
        var currentAttribute = self.ModelJS.UnitAttributesList[self.ModelJS.UnitAttributesList.length - 1];
        var clampVal = currentAttribute.SelectedClampValue;

        if (isInvalid) {
          displayDialogBox(self.translations['Please fill in all fields on the form']);
          return;
        }

        var addNewUnit = true;

        if (currentAttribute.attributes.length == 0 && clampVal == null) {
          addNewUnit = false;
        }

        showAjaxLoader();
        var selectedValues = self.ModelJS.UnitAttributesList[self.ModelJS.SelectedIndex].attributes.reduce(function (acc, val) {
          return acc + val.SelectedAttributeValue + '|';
        }, '');
        var model = clearAttributesList(self.ModelJS);
        frlAjaxService.AddNextUnit(addNewUnit, selectedValues, model).then(function (result) {
          self.ModelJS.SelectedIndex = result.data.SelectedIndex;
          self.ModelJS.UnitAttributesList = result.data.UnitAttributesList;
          self.ModelJS.ShowUnits = result.data.ShowUnits;
          self.ModelJS.ShowUnitData = result.data.ShowUnitData;
          self.ModelJS.ConfigCode = result.data.ConfigCode;
          self.ModelJS.Translations = result.data.Translations;
          self.ModelJS.UnitsList = result.data.UnitsList;
          window.scrollTo(0, 0);
          hideAjaxLoader();
        });
      });
      $scope.$on('event_CompleteConfiguration', function (event, data) {
        var isInvalid = ValidateModel();

        if (isInvalid) {
          displayDialogBox(self.translations['Please fill in all fields on the form']);
          return;
        }

        var isValidFlow = self.ModelJS.Flow == 0 || self.ModelJS.Flow == 1;

        if (!isValidFlow) {
          displayDialogBox(self.translations['Please fill in all fields on the form']);
          return;
        }

        showAjaxLoader();
        var selectedValues = self.ModelJS.UnitAttributesList[self.ModelJS.SelectedIndex].attributes.reduce(function (acc, val) {
          return acc + val.SelectedAttributeValue + '|';
        }, '');
        var AllPipeAdapters = [{
          value: 1,
          label: self.translations['yes'] + " - " + self.ModelJS.Translations["QuikClampText"] + " " + self.ModelJS.Translations["WithBracketText"]
        }, {
          value: 2,
          label: self.translations['yes'] + " - " + self.ModelJS.Translations["QuikClampText"] + " " + self.ModelJS.Translations["WithoutBracketText"]
        }];
        var AllPipeAdaptersStreams = [{
          value: 0,
          label: self.translations['no']
        }, {
          value: 1,
          label: self.ModelJS.Translations["UpStream"]
        }, {
          value: 2,
          label: self.ModelJS.Translations["DownStream"]
        }, {
          value: 3,
          label: self.ModelJS.Translations["Both"]
        }];
        self.ModelJS.PipeAdapterStreams = ApplyPipeAdapterRules(AllPipeAdaptersStreams, self.ModelJS.UnitAttributesList);
        self.ModelJS.PipeAdapterStream = self.ModelJS.PipeAdapterStreams.some(function (adapter) {
          return adapter.value == self.ModelJS.PipeAdapterStream;
        }) ? self.ModelJS.PipeAdapterStream : self.ModelJS.PipeAdapterStreams[0].value;
        self.ModelJS.PipeAdapters = AllPipeAdapters;
        self.ModelJS.ShowPipeAdapters = self.ModelJS.PipeAdapterStream !== 0 ? true : false;
        self.ModelJS.PipeAdapter = self.ModelJS.PipeAdapterStream !== 0 ? 1 : 0;
        var model = clearAttributesList(self.ModelJS);
        model.AccountID = $('#frl-account-id').html();
        model.NorgrenID = $('#frl-norgren-id').html();
        model.DefaultNorgrenID = $('#frl-default-norgren-id').html();
        var langCode = self.ModelJS.LanguageCode;
        var marketCode = self.ModelJS.MarketCode;
        frlAjaxService.CompleteConfiguration(selectedValues, model).then(function (result) {
          self.ModelJS = Object.assign(self.ModelJS, result.data);
          self.ModelJS.LanguageCode = langCode;
          self.ModelJS.MarketCode = marketCode;
          self.ModelJS.IsAdmin = $('#isAdmin').html();
          self.ModelJS.AccountId = getAccountId();
          self.ModelJS.ShowRequestaQuote = self.ModelJS.PriceAndAvailability == null;
          self.ModelJS.CadURL = $sce.trustAsResourceUrl(getCadUrl(self.ModelJS.Series.seriesName, self.ModelJS.CadPnInput, self.ModelJS.Flow, self.ModelJS.ShowConfiguration));
          self.ModelJS.Flow = self.ModelJS.Flow.toString();
          self.ModelJS.BoxSetDatasheet = self.ModelJS.BoxSetDatasheet;
          self.ModelJS.ShowOptions = false;
          $("#userConfigurationId").html(self.ModelJS.SavedConfigID);
          $("#request-a-quote-form .part-numbers").val(result.data.ShortCode);
          setTimeout(function () {
            $('.match-height').css("height", "");
            window.matchColumnHeights();
          }, 0);
          updateDataLayer({
            configuratorStep: self.ModelJS.SelectedIndex + 1,
            partNumber: result.data.ShortCode
          });
          window.scrollTo(0, 0);
          hideAjaxLoader(); // If a pipe adpater is mandated display a message.

          if (self.ModelJS.PipeAdapterStreams.length === 2) {
            $.fancybox({
              content: self.translations['Mandated FRL PipeAdpater Message'],
              modal: false,
              width: 450,
              height: 'auto',
              openEffect: 'none',
              closeEffect: 'none',
              centerOnScroll: true,
              'autoSize': false,
              helpers: {
                overlay: {
                  locked: false
                }
              }
            });
          }
        });
      });
      $scope.$on('event_FRLPrice', function (event, model) {
        showAjaxLoader();
        frlAjaxService.FRLPrice(model, $('#frl-quantity').val()).then(function (response) {
          hideAjaxLoader();
          self.ModelJS.PriceAndAvailability = response.data;
          self.ModelJS.ShowRequestaQuote = self.ModelJS.PriceAndAvailability == null;
        });
      });
      $scope.$on('event_setIndex', function (event, index) {
        self.ModelJS.SelectedIndex = index;
      });
      $scope.$on('event_EditConfiguration', function (event, data) {
        showAjaxLoader();
        frlAjaxService.EditConfiguration(data.index, self.ModelJS).then(function (result) {
          var currentUnitId = result.data.UnitAttributesList[result.data.SelectedIndex].baseUnitId;

          for (var i = 0; i < result.data.UnitsList.length; i++) {
            if (result.data.UnitsList[i].UnitID == currentUnitId) {
              self.ModelJS.SelectedUnit = result.data.UnitsList[i];
              break;
            }
          }

          self.ModelJS.ShowOptions = false;
          self.ModelJS.ShowUnits = result.data.ShowUnits;
          self.ModelJS.ShowClamps = result.data.ShowClamps;
          self.ModelJS.ShowUnitData = result.data.ShowUnitData;
          self.ModelJS.ShowConfiguration = result.data.ShowConfiguration;
          self.ModelJS.UnitAttributesList = result.data.UnitAttributesList;
          self.ModelJS.ConfigCode = result.data.ConfigCode;
          self.ModelJS.SelectedIndex = result.data.SelectedIndex;
          self.ModelJS.ShowRemoveUnitButton = result.data.SelectedIndex == result.data.UnitAttributesList.length - 1;
          self.ModelJS.CadPnInput = result.data.CadPnInput;
          self.ModelJS.CadURL = $sce.trustAsResourceUrl(getCadUrl(self.ModelJS.Series.seriesName, self.ModelJS.CadPnInput, self.ModelJS.Flow, self.ModelJS.ShowConfiguration));
          self.ModelJS.ShowConfigurationButton = result.data.ShowConfigurationButton;
          self.ModelJS.ShowAddUnitButton = result.data.ShowAddUnitButton;
          self.ModelJS.Translations = result.data.Translations;
          setTimeout(function () {
            $('.match-height').css("height", "");
            window.matchColumnHeights();
          }, 0);
          updateDataLayer({
            configuratorStep: self.ModelJS.SelectedIndex + 1
          });
          hideAjaxLoader();
        });
      });
      $scope.$on('event_EditClamp', function (event, data) {
        self.ModelJS.SelectedIndex = data.index;
        self.ModelJS.ShowUnits = false;
        self.ModelJS.ShowClamps = true;
        self.ModelJS.ShowUnitData = false;
        self.ModelJS.ShowConfiguration = false;
      });
      $scope.$on('event_AddToBasket', function (event, data) {
        showAjaxLoader();
        self.ModelJS.BasketItemsNum = 1;
        self.ModelJS.BasketId = $('#frl-basket-id').html();
        self.ModelJS.AccountID = $('#frl-account-id').html();
        self.ModelJS.NorgrenID = $('#frl-norgren-id').html();
        self.ModelJS.DefaultNorgrenID = $('#frl-default-norgren-id').html();
        frlAjaxService.AddToBasket(self.ModelJS).then(function (result) {
          hideAjaxLoader();
          var txt = $('.basket-item-text').html();
          txt = result.data.BasketItemsNum + " " + txt.substring(txt.indexOf('<'), txt.length);
          UpdateBasketDisplay(txt);

          if ($("#dialog-message-vi").length) {
            $("#dialog-message-vi").remove();
          }

          $('body').append($('<div />').attr('id', 'dialog-message-vi').attr('title', window.translation.AddToBasket));
          $("#dialog-message-vi").text(result.data.BasketMessage).hide();
          var buttondialog = window.bskdialogbtns;
          DisplayDialog("#dialog-message-vi", {
            buttons: buttondialog
          });
          DisplayContent(result.data.UmbracoContent);
          self.ModelJS.UnitsList = result.data.UnitsList;
          self.ModelJS.ShowUnits = result.data.ShowUnits;
          self.ModelJS.ShowClamps = result.data.ShowClamps;
          self.ModelJS.ShowUnitData = result.data.ShowUnitData;
          self.ModelJS.ShowConfiguration = result.data.ShowConfiguration;
          self.ModelJS.UnitAttributesList = result.data.UnitAttributesList;
          self.ModelJS.ConfigCode = result.data.ConfigCode;
          self.ModelJS.ShortCode = result.data.ShortCode;
          self.ModelJS.Translations = result.data.Translations;
          self.ModelJS.SelectedIndex = result.data.SelectedIndex;
          self.ModelJS.ShortCode = result.data.ShortCode;
        });
      });

      $scope.updateConfigurationOwner = function () {
        var selectedValues = self.ModelJS.UnitAttributesList[self.ModelJS.SelectedIndex].attributes.reduce(function (acc, val) {
          return acc + val.SelectedAttributeValue + '|';
        }, '');
        self.ModelJS.AccountID = $('#frl-account-id').html();
        self.ModelJS.SavedConfigID = $("#userConfigurationId").html();
        var model = clearAttributesList(self.ModelJS);
        frlAjaxService.SaveConfiguration(selectedValues, model).then(function (result) {
          var response = result.data;

          if (response.SavedConfigID !== -1) {
            self.ModelJS.SavedConfigID = response.SavedConfigID;
            $("#userConfigurationId").html(self.ModelJS.SavedConfigID);
          }
        });
      };

      $scope.$on('event_SaveConfiguration', function (event, data) {
        var isInvalid = self.ModelJS.UnitAttributesList.some(function (element, index) {
          var isInvalid = element.attributes.some(function (attribute, index) {
            return attribute.SelectedAttributeValue === 0;
          });
          return isInvalid;
        });

        if (isInvalid) {
          displayDialogBox(self.translations['Please fill in all fields on the form']);
          return;
        }

        showAjaxLoader();
        var selectedValues = self.ModelJS.UnitAttributesList[self.ModelJS.SelectedIndex].attributes.reduce(function (acc, val) {
          return acc + val.SelectedAttributeValue + '|';
        }, '');
        self.ModelJS.SelectedValues = selectedValues;
        self.ModelJS.AccountID = $('#frl-account-id').html();
        var model = clearAttributesList(self.ModelJS);
        frlAjaxService.SaveConfiguration(selectedValues, model).then(function (result) {
          self.ModelJS.UnitAttributesList = result.data.UnitAttributesList;
          self.ModelJS.ClampsList = result.data.ClampsList;
          self.ModelJS.ShowUnits = result.data.ShowUnits;
          self.ModelJS.ShowUnitData = result.data.ShowUnitData;
          self.ModelJS.ShowClamps = result.data.ShowClamps;
          self.ModelJS.ShowConfiguration = result.data.ShowConfiguration;
          self.ModelJS.ConfigCode = result.data.ConfigCode;
          self.ModelJS.Translations = result.data.Translations;
          self.ModelJS.Flow = result.data.Flow;
          hideAjaxLoader();
          if (result.data.SavedConfigID == "-1") displayDialogBox(self.translations['There was an error saving the configuration, please try again']);else {
            if (getAccountId() === "0") {
              var loginUrl = '/' + window.gwsMc + '/' + window.gwsLc + '/Login.aspx' + '?ReturnUrl=' + encodeURI('/air-preparation-configurator?userConfigurationID=' + result.data.SavedConfigID);
              promptLogin(self.translations['Login to save your configuration'], loginUrl);
            } else {
              displayDialogBox(window.translation.ConfigurationSavedSuccessfully);
              self.ModelJS.SavedConfigID = result.data.SavedConfigID;
              $("#savedUserConfigurationId").html(result.data.SavedConfigID);
            }
          }
        });
      });
      $scope.$on('event_UpdateUnitData', function (event, attributeValueId, attributeId, baseUnitId, unitAttributesList) {
        var selectedValues = self.ModelJS.UnitAttributesList[self.ModelJS.SelectedIndex].attributes.reduce(function (acc, val) {
          return acc + val.SelectedAttributeValue + '|';
        }, '');
        frlAjaxService.UpdateUnitData(baseUnitId, attributeId, attributeValueId, selectedValues, unitAttributesList, self.ModelJS.SelectedIndex, self.ModelJS.Series, self.ModelJS.LanguageCode, self.ModelJS.MarketCode).then(function (data) {
          if (data.data) {
            self.ModelJS.SelectedUnit.UnitImage = data.data.ImageLink;
            self.ModelJS.UnitAttributesList = data.data.UnitAttributesList;
            $scope.$emit('event_UpdateConfigurationCode', {
              marketCode: self.ModelJS.MarketCode,
              languageCode: self.ModelJS.LanguageCode,
              unitAttributesList: self.ModelJS.UnitAttributesList,
              Flow: self.ModelJS.Flow,
              PipeAdapter: self.ModelJS.PipeAdapter,
              PipeAdapterStream: self.ModelJS.PipeAdapterStream,
              Series: self.ModelJS.Series
            }, false);
          }
        });
      });
      $scope.$on('event_RemoveUnit', function (event) {
        if (self.ModelJS.UnitAttributesList.length == 1) {
          $scope.$emit('event_DiscardConfiguration');
          return;
        }

        self.ModelJS.PipeAdapterStream = 0; //default to None

        self.ModelJS.PipeAdapter = 0;
        var model = clearAttributesList(self.ModelJS);
        var langCode = self.ModelJS.LanguageCode;
        var marketCode = self.ModelJS.MarketCode;
        frlAjaxService.RemoveUnit(model).then(function (data) {
          self.ModelJS = data.data;
          self.ModelJS.LanguageCode = langCode;
          self.ModelJS.MarketCode = marketCode;
          $scope.$emit('event_EditConfiguration', {
            index: self.ModelJS.SelectedIndex
          });
        });
      });
      $scope.$on('event_PartChange', function (event) {
        var model = clearAttributesList(self.ModelJS);
        frlAjaxService.PartChange(model).then(function (response) {
          self.ModelJS.ShortCode = response.data.ShortCode;
          self.ModelJS.ShowBom = response.data.ShowBom;
          self.ModelJS.CadPnInput = response.data.CadPnInput;
          self.ModelJS.CadURL = $sce.trustAsResourceUrl(getCadUrl(self.ModelJS.Series.seriesName, response.data.CadPnInput, self.ModelJS.Flow, self.ModelJS.ShowConfiguration));
          self.ModelJS.BoxSetDatasheet = response.data.BoxSetDatasheet;
          $("#request-a-quote-form .part-numbers").val(response.data.ShortCode);
        });
      });
      $scope.$on('event_UpdateConfigurationCode', function (event, model, doPandA) {
        frlAjaxService.UpdateConfigurationCode(model).then(function (data) {
          self.ModelJS.ConfigCode = data.data.ConfigurationCode;
          self.ModelJS.CadPnInput = data.data.CadPnInput;
          self.ModelJS.CadURL = $sce.trustAsResourceUrl(getCadUrl(self.ModelJS.Series.seriesName, data.data.CadPnInput, self.ModelJS.Flow, self.ModelJS.ShowConfiguration));
          self.ModelJS.UnitAttributesList = data.data.UnitAttributesList;

          if (doPandA) {
            $scope.$emit('event_FRLPrice', self.ModelJS);
          }
        });
      });
      $scope.$on('event_ProductAccessoryListEvent', function (event, data) {
        showAjaxLoader();
        frlAjaxService.GetLoggedInAccountId(self.ModelJS.MarketCode, self.ModelJS.LanguageCode).then(function (result) {
          var accountId = parseFloat(result.data);

          if (isNaN(accountId) === false && accountId != 0) {
            frlAjaxService.GetNorgrenAccountId(self.ModelJS.MarketCode, self.ModelJS.LanguageCode).then(function (result) {
              var norgrenAccountId = result.data;
              frlAjaxService.GetAccessoriesPrice(self.ModelJS.MarketCode, self.ModelJS.LanguageCode, norgrenAccountId, self.ModelJS.FrlAccessories).then(function (result) {
                self.ModelJS.FrlAccessories = result.data;
                hideAjaxLoader();
              });
            });
          } else {
            hideAjaxLoader();
            displayDialogBox(self.translations['Extrabase PandA Login for price']);
          }
        });
      });
      $scope.$on('event_ProductAccesoryAddToBasketEvent', function (event, data) {
        showAjaxLoader();
        frlAjaxService.GetLoggedInAccountId(self.ModelJS.MarketCode, self.ModelJS.LanguageCode).then(function (result) {
          var accountId = parseFloat(result.data);

          if (isNaN(accountId) === false && accountId != 0) {
            frlAjaxService.GetBasketId().then(function (result) {
              var basketId = result.data;
              frlAjaxService.AccessoriesAddToBasket(self.ModelJS.LanguageCode, basketId, self.ModelJS.FrlAccessories).then(function (result) {
                // Update the basket item count.
                hideAjaxLoader();
                displayDialogBox(result.data);
              });
            });
          } else {
            hideAjaxLoader();
            displayDialogBox(self.translations['Extrabase PandA Login for price']);
          }
        });
      });

      var ValidateModel = function ValidateModel() {
        var isInvalid = self.ModelJS.UnitAttributesList.some(function (element, index) {
          var isInvalid = element.attributes.some(function (attribute, index) {
            return attribute.SelectedAttributeValue <= 0;
          });
          return isInvalid;
        });
        return isInvalid;
      };

      self.translations = {};

      function getTranslations(keys) {
        var deferred = $q.defer();
        keys.forEach(function (key, index) {
          window.getTranslation(key).then(function (data) {
            $scope.$apply(function () {
              self.translations[key] = data;
            });

            if (index === keys.length - 1) {
              //resolve the promise after the final translation is complete
              deferred.resolve();
            }
          });
        });
        return deferred.promise;
      }

      getTranslations(["Product Datasheet (PDF)", "frl-unit-not-available", "UnitSelectIntroduction", "UnitSelectFooter", "UnitSelectIntroduction", "UnitDisabledIntroduction", "Gauge option warning", "Please Select", "Complete Configuration", "Add another Unit", "Discard Configuration", "Configure", "Remove unit", "Your configuration", "CAD Model", "Flow", "Flow tool tip", "LeftToRight", "RightToLeft", "Pipe adapter tool tip", "no", "yes", "More Information", "Edit Configuration", "Item", "Quantity", "Unit Price", "Total price", "Delivery", "Save for later", "Add To Basket", "Request a Quote", "Please login or register", "View/Download 3D CAD", "Clamps Header", "Quik clamp", "Box Set Datasheet", "Mandated FRL PipeAdpater Message", "FrlSeriesHeader", "FrlSeriesIntroduction", "FRL82 Series", "FRL84 Series", "HFRL84 Series", "price and availability", "VIEW", "Accessory", "Products", "Price", "Accessories", "Down Stream", "Up Stream", "Both", "Stream", "Please fill in all fields on the form", "Extrabase PandA Login for price", "Login to save your configuration", "There was an error saving the configuration, please try again", "Part Number", "P-valve Accessories", "Salesdesk: No search results returned", "Unit", "Clamp", "Complete Configuration", "Options", "FRL82 Series Description", "FRL84 Series Description", "HFRL84 Series Description", "UnitSelectIntroduction_82", "UnitSelectIntroduction_84", "UnitSelectIntroduction_H84"]);

      var ApplyPipeAdapterRules = function ApplyPipeAdapterRules(PipeAdapters, Units) {
        var containsPressureBlock = Units[Units.length - 1].baseUnitId === 11;
        var isNptThread = Units[0].attributes[0].SelectedAttributeValue === 4 || Units[0].attributes[0].SelectedAttributeValue === 5 || Units[0].attributes[0].SelectedAttributeValue === 6;
        var contains18DManifoldBlockAndConfigIsNptThread = Units.some(function (unit) {
          var lastUnitConfigCode = Units[Units.length - 1].ConfigCode;
          return isNptThread && (lastUnitConfigCode === '0337717000000000' || lastUnitConfigCode === 'NE/13482' || lastUnitConfigCode === 'NE/13521');
        });

        if (containsPressureBlock || contains18DManifoldBlockAndConfigIsNptThread) {
          PipeAdapters = PipeAdapters.filter(function (pipeadapter) {
            return pipeadapter.value === 2 || pipeadapter.value === 3;
          });
        }

        return PipeAdapters;
      };
    }],
    templateUrl: "/mkt/ln/js/app/templates/frl/mainController-template.html"
  });
  app.component('unitList', {
    bindings: {
      model: '=',
      translations: '='
    },
    controller: ['$scope', '$attrs', '$sce', function ($scope, $attrs, $sce) {
      var self = this;

      $scope.getSeriesImages = function () {
        $scope.$emit('event_GetSeriesImages');
      };

      $scope.raiseGetUnitsEvent = function (series) {
        $scope.$emit('event_GetUnits', series);
      };

      $scope.raiseUnitEvent = function (unit) {
        $scope.$emit('event_UnitSelected', unit);
      };

      $scope.raiseDiscardEvent = function () {
        $scope.$emit('event_DiscardConfiguration');
      };

      $scope.raiseAddNextUnit = function () {
        $scope.$emit('event_AddNextUnit');
      };

      $scope.raiseCompleteEvent = function () {
        $scope.$emit('event_CompleteConfiguration');
      };

      $scope.raiseAddClampEvent = function () {
        $scope.$emit('event_AddClamp');
      };

      $scope.raiseEditEvent = function (index) {
        $scope.$emit('event_EditConfiguration', {
          index: index
        });
      };

      $scope.raiseAddToBasketEvent = function () {
        $scope.$emit('event_AddToBasket');
      };

      $scope.raiseSaveEvent = function () {
        $scope.$emit('event_SaveConfiguration');
      };

      $scope.raiseRemoveFromBasket = function (item) {
        $scope.$emit('event_raiseRemoveFromBasket', {
          item: item
        });
      };

      $scope.raiseUpdatePAndA = function (modelJS) {
        $scope.$emit('event_FRLPrice', modelJS);
      };

      $scope.raiseUpdateUnitData = function (attributeValueId, attribute, unit, unitAttributesList, series) {
        //to update the image we need to do an ajax update to request the base part number and the attribute values we're updating
        $scope.$emit('event_UpdateUnitData', attributeValueId, attribute, unit, unitAttributesList, series);
      };

      $scope.raiseRemoveEvent = function () {
        $scope.$emit('event_RemoveUnit');
      };

      $scope.raisePartChange = function () {
        $scope.$emit('event_PartChange', {});
      };

      $scope.raiseOptionsEvent = function () {
        $scope.$emit('event_Options');
      };

      $scope.raiseConfigurationCodeEvent = function (unitAttributesList) {
        var doPandA = self.model.AccountID !== "0" && self.model.AllowAnonPrices === false;
        $scope.$emit('event_UpdateConfigurationCode', {
          marketCode: self.model.MarketCode,
          languageCode: self.model.LanguageCode,
          unitAttributesList: unitAttributesList,
          Flow: self.model.Flow,
          PipeAdapter: self.model.PipeAdapter,
          PipeAdapterStream: self.model.PipeAdapterStream,
          Series: self.model.Series
        }, doPandA);
      };

      $scope.raiseAccessoriesPriceEvent = function () {
        $scope.$emit('event_ProductAccessoryListEvent', {
          data: {}
        });
      };

      $scope.raiseAddAccessoriesToBasketEvent = function () {
        $scope.$emit('event_ProductAccesoryAddToBasketEvent', {
          data: {}
        });
      };

      $scope.raiseNavEvent = function (index) {
        self.activenavindex = index;

        if (self.model.UnitAttributesList[index].baseUnitId === 0) {
          $scope.$emit('event_setIndex', index);
          $scope.$emit('event_AddNextUnit');
        }

        if (self.model.UnitAttributesList[index].baseUnitId !== 0) {
          $scope.$emit('event_EditConfiguration', {
            index: index
          });
        }
      };

      $scope.sanitize = function (tooltip) {
        return $sce.trustAsHtml(tooltip);
      };

      $scope.hidePipeAdapterClamp = function (selectedPipeAdapterStream) {
        var selectPipeAdapter = 0;
        if (selectedPipeAdapterStream != 0 && self.model.PipeAdapter == 0) selectPipeAdapter = 1;else if (selectedPipeAdapterStream != 0) selectPipeAdapter = self.model.PipeAdapter;
        self.model.PipeAdapter = selectPipeAdapter;
        self.model.ShowPipeAdapters = selectedPipeAdapterStream != 0;
      };
    }],
    templateUrl: '/mkt/ln/js/app/templates/frl/unitList-template.html'
  });

  function InitModelJS() {
    var model = {
      AllowAnonPrices: window.gwsShp,
      MarketCode: window.gwsMc,
      LanguageCode: window.gwsLc,
      ShowSeries: true,
      EightyTwoSeriesEnabled: window.gwsFrl82Enabled,
      EightyFourSeriesEnabled: window.gwsFrl84Enabled,
      HEightyFourSeriesEnabled: window.gwsHFrl84Enabled,
      ConfigCode: "",
      Flow: 0,
      PipeAdapterStream: 0,
      PipeAdapter: 0,
      SavedConfigID: 0,
      ShowUnits: false,
      ShowUnitData: false,
      ShowClamps: false,
      UnitAttributesList: [{
        baseUnitId: 0
      }],
      ClampsList: [],
      IsAdmin: $('#isAdmin').html(),
      Translations: {}
    };
    return model;
  }

  function InititaliseService($http) {
    var service = {};

    service.GetSeriesImages = function () {
      var request = {
        url: '/api/FRL/SeriesImages',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      };
      return $http(request);
    };

    service.GetUnits = function (seriesId) {
      var request = {
        url: '/api/FRL/Units',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          MarketCode: window.gwsMc,
          LanguageCode: window.gwsLc,
          SeriesId: seriesId
        }
      };
      return $http(request);
    };

    service.UnitSelected = function (unitId, code, model) {
      var request = {
        url: '/api/FRL/UnitSelected',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          MarketCode: window.gwsMc,
          LanguageCode: window.gwsLc,
          UnitId: unitId,
          Code: code,
          model: model
        }
      };
      return $http(request);
    };

    service.GetClamps = function (language) {
      var request = {
        url: '/api/FRL/Clamps',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: JSON.stringify(language)
      };
      return $http(request);
    };

    service.AddNextUnit = function (addNewUnit, selectedValues, model) {
      var request = {
        url: '/api/FRL/AddNextUnit',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          addNewUnit: addNewUnit,
          selectedValues: selectedValues,
          model: model
        }
      };
      return $http(request);
    };

    service.AddClamp = function (unitId, code) {
      var request = {
        url: '/api/FRL/AddClamp',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          MarketCode: window.gwsMc,
          LanguageCode: window.gwsLc,
          UnitId: unitId,
          Code: code
        }
      };
      return $http(request);
    };

    service.CompleteConfiguration = function (selectedValues, model) {
      var request = {
        url: '/api/FRL/CompleteConfiguration',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          selectedValues: selectedValues,
          model: model
        }
      };
      return $http(request);
    };

    service.EditConfiguration = function (index, model) {
      var request = {
        url: '/api/FRL/EditConfiguration',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          index: index,
          model: model
        }
      };
      return $http(request);
    };

    service.AddToBasket = function (model) {
      var request = {
        url: '/api/FRL/AddToBasket',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          model: model
        }
      };
      return $http(request);
    };

    service.SaveConfiguration = function (selectedValues, model) {
      var request = {
        url: '/api/FRL/SaveConfiguration',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          selectedValues: selectedValues,
          model: model
        }
      };
      return $http(request);
    };

    service.FRLPrice = function (model, qty) {
      model.NorgrenID = $('#frl-norgren-id').html();
      model.DefaultNorgrenID = $('#frl-default-norgren-id').html();
      var request = {
        url: '/api/FRL/FRLPrice',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          Quantity: qty,
          MarketCode: model.MarketCode,
          CultureCode: model.LanguageCode,
          ConfigCode: model.ConfigCode,
          NorgrenID: model.AccountId > 0 ? model.NorgrenID : model.DefaultNorgrenID,
          NumberOfUnits: model.UnitAttributesList.length,
          PipeAdapter: model.PipeAdapter,
          PipeAdapterStream: model.PipeAdapterStream,
          SeriesId: model.Series.seriesID,
          UnitAttributesList: model.UnitAttributesList
        }
      };
      return $http(request);
    };

    service.LoadSavedConfiguration = function (configId, partNumber, model) {
      var request = {
        url: '/api/FRL/LoadSavedConfiguration',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          configId: configId,
          partNumber: partNumber,
          model: model
        }
      };
      return $http(request);
    };

    service.UpdatePAndA = function (qty, marketCode, cultureCode, configCode, norgrenId) {
      var request = {
        url: '/api/FRL/UpdatePAndA',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          qty: qty,
          marketCode: marketCode,
          cultureCode: cultureCode,
          configCode: configCode,
          norgrenId: norgrenId
        }
      };
      return $http(request);
    };

    service.UpdateUnitData = function (unitId, attributeId, attributeValueId, selectedValues, unitAttributesList, index, series) {
      var request = {
        url: '/api/FRL/UpdateUnitData',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          unitId: unitId,
          attributeId: attributeId,
          attributeValueId: attributeValueId,
          selectedValues: selectedValues,
          UnitAttributesList: unitAttributesList,
          index: index,
          series: series,
          languageCode: window.gwsLc,
          marketCode: window.gwsMc
        }
      };
      return $http(request);
    };

    service.RemoveUnit = function (model) {
      var request = {
        url: '/api/FRL/RemoveUnit',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          model: model
        }
      };
      return $http(request);
    };

    service.ShowOptions = function (model) {
      var request = {
        url: '/api/FRL/ShowOptions',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          model: model
        }
      };
      return $http(request);
    };

    service.PartChange = function (model) {
      var request = {
        url: '/api/FRL/PartChange',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          model: model
        }
      };
      return $http(request);
    };

    service.UpdateConfigurationCode = function (model) {
      var request = {
        url: '/api/FRL/UpdateConfigurationCode',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          model: model
        }
      };
      return $http(request);
    };

    service.GetAccessoriesPrice = function (marketCode, languageCode, norgrenAccountId, accessories) {
      var request = {
        url: '/api/FRL/GetAccessoriesPrice',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          MarketCode: marketCode,
          LanguageCode: languageCode,
          NorgrenAccountId: norgrenAccountId,
          Accessories: accessories
        }
      };
      return $http(request);
    };

    service.GetLoggedInAccountId = function (gwsMc, gwsLc) {
      var url = '/' + gwsMc + '/' + gwsLc + '/service/getloggedinaccountid/ajax.aspx';
      var request = {
        url: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {}
      };
      return $http(request);
    };

    service.GetNorgrenAccountId = function (gwsMc, gwsLc) {
      var url = '/' + gwsMc + '/' + gwsLc + '/service/getnorgrenaccountid/ajax.aspx';
      var request = {
        url: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {}
      };
      return $http(request);
    };

    service.GetBasketId = function () {
      var url = '/' + window.gwsMc + '/' + window.gwsLc + '/service/getbasketid/ajax.aspx';
      var request = {
        url: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {}
      };
      return $http(request);
    };

    service.AccessoriesAddToBasket = function (languageCode, basketId, frlAccessories) {
      var request = {
        url: '/api/FRL/AccessoriesAddToBasket',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          LanguageCode: languageCode,
          BasketId: basketId,
          FrlAccessories: frlAccessories
        }
      };
      return $http(request);
    };

    return service;
  }

  function clearAttributesList(model) {
    for (var ii = 0; ii < model.UnitAttributesList.length; ii++) {
      for (var kk = 0; kk < model.UnitAttributesList[ii].length; kk++) {
        model.UnitAttributesList[ii][kk].AttributeList = null;
      }
    }

    return model;
  }

  function displayDialogBox(message) {
    if ($("#dialog-message-vi").length) {
      $("#dialog-message-vi").remove();
    }

    $('body').append($('<div />').attr('id', 'dialog-message-vi').attr('title', window.translation.FRLConfigurator));
    $("#dialog-message-vi").text(message).hide();
    DisplayDialog("#dialog-message-vi", {
      buttons: {
        Ok: function Ok() {
          $(this).dialog("close");
        }
      }
    });
  }

  function promptLogin(message, url) {
    if ($("#dialog-message-vi").length) {
      $("#dialog-message-vi").remove();
    }

    $('body').append($('<div />').attr('id', 'dialog-message-vi').attr('title', window.translation.FRLConfigurator));
    $("#dialog-message-vi").text(message).hide();
    DisplayDialog("#dialog-message-vi", {
      buttons: {
        Ok: function Ok() {
          $(this).dialog("close");
          window.location.href = url;
        }
      }
    });
  }

  function getAccountId() {
    var idNum = $('#frl-account-id').html();
    return idNum;
  }

  ;

  function showAjaxLoader() {
    $("#ajax_loader_vi").show();
  }

  function hideAjaxLoader() {
    $("#ajax_loader_vi").hide();
  }

  $(document).on("click", function (event) {
    event.stopImmediatePropagation();

    if ($(event.target).is(".vi-fancy-select li span")) {
      window.manageFancyDropDown($(event.target).parent());
      return true;
    }

    if ($(event.target).is(".vi-fancy-select li")) {
      window.manageFancyDropDown($(event.target));
      return true;
    }

    if ($(event.target).is(".vi-fancy-select")) {
      window.manageFancyDropDown($(event.target).find("li:visible").first());
      return true;
    } // close any open dropdowns.


    if (!$(event.target).is(".vi-fancy-select li")) {
      $("div.vi-fancy-select.open").each(function () {
        window.closeFancyListBox($(this));
      });
    }
  });

  window.manageFancyDropDown = function (selectedElem) {
    var parent = selectedElem.parent().parent(); // check to see if this select box is open.

    var isOpen = parent.hasClass("open");

    if (isOpen) {
      var selectedValue = selectedElem.find("span").html();
      var value = parent.prev().find('select option').filter(function (index) {
        return $(this).text() === selectedValue;
      }).val();
      var select = parent.prev().find("select");

      if (select.val() != value) {
        select.val(value).change();
      }

      window.closeFancyListBox(parent); // The main-content class has overflow: hidden set by default. This potenially hides options
      // in the dropdown if they go past the footer. It needs to set to visible while the 
      // dropdown is open and reset to hidden when closed. Here the dropdown is being closed so 
      // overflow goes back to hidden

      $('.main-content').css("overflow", "hidden");
    } else {
      // close all the other list boxes.
      $("div.vi-fancy-select.open").not(parent).each(function () {
        window.closeFancyListBox($(this));
      }); // The dropdown is being opened so overflow must be visible to see all options in the list

      $('.main-content').css("overflow", "visible");
      parent.css("z-index", 9999999);
      parent.find("li").removeClass("hidden");
      parent.removeClass("closed").addClass("open");
      parent.css("overflow", "visible");
      parent.css("width", "556");
    }
  };

  window.closeFancyListBox = function (fancyListBox) {
    var val = fancyListBox.prev().find("select option:selected").text();
    fancyListBox.find("li").each(function () {
      if ($(this).find("span").html() != val) {
        $(this).addClass("hidden");
      }
    });
    fancyListBox.removeClass("open").addClass("closed");
    fancyListBox.css("z-index", 1);
    fancyListBox.css("width", "");
    fancyListBox.css("overflow", "hidden");
  };

  function getCadUrl(seriesName, pnInputString, flowNum, completionStage) {
    var emailAddress = $("#emailAddress").html();
    var cadUrl = "";

    if (pnInputString != "") {
      var cadUrlSuffix = seriesName === "H84" ? "excelon_tr_84/excelon_tbe_asmtab.prj" : "excelon_" + seriesName + "/excelon_" + seriesName + "_asmtab.prj";
      cadUrl = window.gwsCadenasBaseDomain + "?info=imi_precision/2_air_preparation/" + cadUrlSuffix + "%26varset={FLOW=" + flowNum + "}," + pnInputString + "%26hidePortlets=navigation%26hidePortlets=generation";
    }

    if (completionStage) {
      cadUrl += "%26showPortlets=generation";
      if (emailAddress != null) cadUrl += "%26email=" + emailAddress;
    }

    return cadUrl;
  }

  function initialiseDataLayerTracking() {
    window.dataLayer = window.dataLayer || {};
    dataLayer[0].configuratorStep = 1;
  }

  function updateDataLayer(dataLayer) {
    window.dataLayer[0] = Object.assign(window.dataLayer[0], dataLayer);
  }

  function setCadFancyBoxDimensions() {
    $(".fancybox").fancybox({
      modal: false,
      height: 750,
      openEffect: 'none',
      closeEffect: 'none',
      centerOnScroll: true,
      'autoSize': false,
      helpers: {
        overlay: {
          locked: false
        }
      }
    });
  }
})();