"use strict";

//"use strict";
(function () {
  angular.module("norgrenApp").component("stockLocatorModalController", {
    controller: function controller($modal, $scope, $log, $attrs, $rootScope) {
      var ctrl = $scope.$ctrl;

      ctrl.$onInit = function () {
        ctrl.postCode = ctrl.postCode || $attrs.postcode;
        ctrl.marketCode = ctrl.marketCode || $attrs.marketcode;
        ctrl.cultureCode = ctrl.cultureCode || $attrs.culturecode;
        ctrl.itemCode = ctrl.itemCode || $attrs.itemcode;
        ctrl.itemImage = ctrl.itemImage || $attrs.itemimage;
        ctrl.itemDescription = ctrl.itemDescription || $attrs.itemdescription;
        ctrl.countryCode = ctrl.countryCode || $attrs.countrycode;
        ctrl.buttonText = ctrl.buttonText || $attrs.buttontext;
        ctrl.buttonCss = ctrl.buttonCss || $attrs.buttoncss || "btn btn-block";
        ctrl.countries = ctrl.countries || $attrs.countries;
        ctrl.selectedCountry = "United States";
      };

      ctrl.open = function (size) {
        var modalInstance = $modal.open({
          templateUrl: "stockLocatorComponent.html",
          controller: 'StockLocatorController1',
          size: size,
          backdrop: "static",
          backdropClass: "stock-item-modal__backdrop",
          windowClass: "stock-item-modal__window",
          scope: $scope,
          resolve: {
            postCode: function postCode() {
              return ctrl.postCode;
            },
            marketCode: function marketCode() {
              return ctrl.marketCode;
            },
            cultureCode: function cultureCode() {
              return ctrl.cultureCode;
            },
            itemCode: function itemCode() {
              return ctrl.itemCode;
            },
            itemImage: function itemImage() {
              return ctrl.itemImage;
            },
            itemDescription: function itemDescription() {
              return ctrl.itemDescription;
            },
            countryCode: function countryCode() {
              return ctrl.countryCode;
            },
            showStock: function showStock() {
              return ctrl.showStock;
            },
            countries: function countries() {
              return ctrl.countries;
            }
          }
        });
        modalInstance.result.then(function (selectedItem) {}, function () {
          $log.info("Modal dismissed at: " + new Date());
        });
      };

      $rootScope.$on("openStockLocator", function (event, args) {
        ctrl.open();
      });
    },
    templateUrl: "/mkt/ln/js/app/templates/Stock-Locator/mainController-template.html",
    bindings: {
      postCode: "<",
      marketCode: "<",
      cultureCode: "<",
      itemCode: "<",
      itemImage: "<",
      itemDescription: "<",
      countryCode: "<",
      buttonText: "<",
      buttonCss: "@?",
      showStock: "@",
      countries: "<",
      selectedCountry: "<"
    }
  });
})();