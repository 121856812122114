"use strict";

(function () {
  "use strict"; //Want a singleton instance of the service so using the factory method.

  angular.module("norgrenApp").factory("distributorStockService", ["stockObjectList", distributorStockService]);

  function distributorStockService(stockObjectList) {
    var data = {
      stockObjects: [],
      currentLocation: {},
      dataLoaded: false,
      hasError: false,
      errorMessage: ""
    };
    return {
      getStockObjects: function getStockObjects() {
        return data.stockObjects;
      },
      getCurrentLocation: function getCurrentLocation() {
        return data.currentLocation;
      },
      getIsError: function getIsError() {
        return data.hasError;
      },
      getErrorMessage: function getErrorMessage() {
        return data.errorMessage;
      },
      isDataLoaded: function isDataLoaded() {
        return data.dataLoaded;
      },
      setData: function setData(responseData) {
        data = stockObjectList.build(responseData);
      },
      setDataIsLoaded: function setDataIsLoaded(isDataLoaded) {
        data.dataLoaded = isDataLoaded;
      },
      setError: function setError(msg) {
        data.hasError = true;
        data.errorMessage = msg;
      }
    };
  }
})();