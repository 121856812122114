"use strict";

(function () {
  function libraryDownload() {}

  angular.module('norgrenApp').component('libraryDownload', {
    templateUrl: '/mkt/ln/js/app/templates/ContentItemsLister/library-download-template.html',
    controller: libraryDownload,
    bindings: {
      item: '='
    }
  });
})();