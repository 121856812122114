"use strict";

(function () {
  function libraryPreview($scope) {
    var main = $scope.$ctrl;

    main.scrollTo = function (id) {
      var el = document.getElementById(id);
      window.scroll(0, findPos(el));
      var header = angular.element(document.getElementById("library-header"));
      var pageHeader = angular.element(document.getElementsByTagName('header')); // now account for fixed header

      var scrolledY = window.scrollY;

      if (scrolledY) {
        window.scroll(0, scrolledY - header.outerHeight(true));
        scrolledY = window.scrollY;
        window.scroll(0, scrolledY - pageHeader.outerHeight(true));
        console.log(window.scrollY);
      }
    };
  }

  function findPos(pageElement) {
    var curtop = 0;
    var headerRow = angular.element(pageElement.getElementsByClassName("library-category-container__header-row"));
    var offset = headerRow.outerHeight(true);

    if (pageElement.offsetParent) {
      do {
        curtop += pageElement.offsetTop;
      } while (pageElement = pageElement.offsetParent);

      return [curtop - offset];
    }
  }

  angular.module("norgrenApp").component("libraryPreview", {
    templateUrl: '/mkt/ln/js/app/templates/ContentItemsLister/library-preview-template.html',
    controller: libraryPreview,
    bindings: {
      categories: "=",
      title: "=",
      learnMore: "="
    }
  });
})();