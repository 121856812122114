"use strict";

(function () {
  "use strict";

  angular.module('norgrenApp').service('libraryAjaxService', ["$http", libraryAjaxService]);

  function libraryAjaxService($http) {
    var service = this;
    var apiEndpoint = "/api/LibraryContent/";

    service.getItems = function (path, marketCode, searchTerm) {
      var request = {
        url: apiEndpoint + "GetContentItems",
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        data: {
          marketCode: marketCode,
          path: path,
          searchTerm: searchTerm
        }
      };
      return $http(request);
    };

    return service;
  }
})();