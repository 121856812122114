"use strict";

(function () {
  function libaryCategory($scope) {
    var main = $scope.$ctrl;
    main.collapsed = false;

    main.openPanel = function () {
      main.collapsed = !main.collapsed;
    };
  }

  angular.module('norgrenApp').component('libaryCategory', {
    templateUrl: '/mkt/ln/js/app/templates/ContentItemsLister/library-category-template.html',
    controller: libaryCategory,
    bindings: {
      category: '=',
      sortByOptions: '=',
      itemTypes: '=',
      sortByPlaceholder: '=',
      useCarousel: "=",
      usePagination: "="
    }
  });
})();