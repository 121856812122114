"use strict";

(function () {
  "use strict";

  function libraryTags($scope) {
    var main = $scope.$ctrl;
    var urlParams = new URLSearchParams(window.location.search);
    var category = urlParams.get("category");

    if (category) {
      var tag = main.tags.find(function (e) {
        return e.value === category;
      });

      if (tag) {
        main.update(tag);
      }
    }
  }

  angular.module('norgrenApp').component('libraryTags', {
    templateUrl: '/mkt/ln/js/app/templates/ContentItemsLister/library-tags-template.html',
    controller: libraryTags,
    bindings: {
      tags: "=",
      update: "<"
    }
  });
})();