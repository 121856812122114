"use strict";

(function () {
  function librarySort($scope, $sce) {
    var main = $scope.$ctrl;
    var orderByObjects = [sortPublishedDateAsc, sortPublishedDateDec];
    main.sortItem = undefined;

    main.getPlaceHolderText = function () {
      return main.placeholderText || $sce.valueOf(main.placeholder);
    };

    main.sortAction = function () {
      if (!main.sortItem && main.sortItem.id >= 0) return;
      var filterObj = orderByObjects[main.sortItem.id];

      if (main.category) {
        main.category.items.sort(filterObj);
      } else if (main.data) {
        main.data.categories.forEach(function (item) {
          return item.items.sort(filterObj);
        });
      }
    };

    function sortPublishedDateDec(item1, item2) {
      return new Date(item1.publishedDate) - new Date(item2.publishedDate);
    }

    function sortPublishedDateAsc(item1, item2) {
      return new Date(item2.publishedDate) - new Date(item1.publishedDate);
    }
  }

  angular.module('norgrenApp').component('librarySort', {
    templateUrl: '/mkt/ln/js/app/templates/ContentItemsLister/library-sort-template.html',
    controller: librarySort,
    bindings: {
      category: "=",
      sortByOptions: "=",
      placeholder: "=",
      placeholderText: "<",
      data: "=",
      usePagination: "="
    }
  });
})();