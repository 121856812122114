"use strict";

if (!window.getPriceAndAvailability) {
  window.getPriceAndAvailability = function (sku) {
    var deferred = $.Deferred();
    $.post(window.appPath + '/service/price/ajax.aspx', {
      itemCode: sku.replace(/\+/g, '%2B'),
      quantity: 1
    }, function (data) {
      deferred.resolve(data);
    });
    return deferred.promise();
  };
}

if (!window.getPriceAndAvailabilityForQty) {
  window.getPriceAndAvailabilityForQty = function (sku, qty) {
    var deferred = $.Deferred(); //$.post('/' + window.gwsMc + '/' + window.gwsLc + '/service/price/ajax.aspx', { itemCode: sku.replace(/\+/g, '%2B'), quantity: qty }, function (data) {// Commented as causing issues with items such as GT52+VI-25

    $.post(window.appPath + '/service/price/ajax.aspx', {
      itemCode: sku,
      quantity: qty
    }, function (data) {
      deferred.resolve(data);
    });
    return deferred.promise();
  };
}

if (!window.getPromotionPrice) {
  window.getPromotionPrice = function (sku) {
    var deferred = $.Deferred();
    $.post(window.appPath + '/service/getpromotionforproduct/ajax.aspx', {
      productCode: sku.replace(/\+/g, '%2B')
    }, function (data) {
      deferred.resolve(data);
    });
    return deferred.promise();
  };
}

if (!window.getAddToBasketResult) {
  window.getAddToBasketResult = function (sku, qty, umbracoContentType, configType, currency) {
    var deferred = $.Deferred();
    $.post(window.appPath + '/service/AddToBasket/ajax.aspx', {
      itemCode: sku,
      quantity: qty,
      umbracoContentType: umbracoContentType,
      configType: configType,
      currency: currency
    }, function (data) {
      deferred.resolve(data);
    });
    return deferred.promise();
  };
}

if (!window.getAdaptixPrice) {
  window.getAdaptixPrice = function (selectedCurrency, configurationType) {
    var deferred = $.Deferred();
    $.post('/api/SoftJaw/PriceOverride', {
      currency: selectedCurrency,
      configurationType: configurationType
    }, function (data) {
      deferred.resolve(data);
    });
    return deferred.promise();
  };
}

if (!window.getAddToBasketResultWithPandA) {
  window.getAddToBasketResultWithPandA = function (sku, qty) {
    var deferred = $.Deferred();
    $.post(window.appPath + '/service/PriceAndAvailability/ajax.aspx', {
      itemCode: sku.replace(/\+/g, '%2B'),
      quantity: qty,
      action: 'priceandavailability'
    }, function (data) {
      deferred.resolve(data);
    });
    return deferred.promise();
  };
}

if (!window.getFredHopperItemAttributes) {
  window.getFredHopperItemAttributes = function (partNo) {
    var deferred = $.Deferred();
    $.post(window.appPath + '/service/getFredhopperAccessoryInfo/ajax.aspx', {
      itemCode: partNo.replace(/\+/g, '%2B')
    }, function (data) {
      if (data !== null) {
        deferred.resolve($(data).find('string').text());
      } else {
        deferred.resolve(null);
      }
    });
    return deferred.promise(); //var attr = "";
    //  $.ajax({
    //        type: "POST",
    //        url: "/" + window.gwsMc + "/" + window.gwsLc + "/service/getFredhopperAccessoryInfo/ajax.aspx" + "?itemCode=" + partNo,
    //        async: false,
    //        dataType: "xml",
    //    error: function () {},
    //        success: function(data) {
    //            attr = $(data).text();
    //        	return attr;
    //        },
    //    complete: function () {}
    //    });
    //return attr;
  };
}

if (!window.getTranslation) {
  window.getTranslation = function (translationTag) {
    var deferred = $.Deferred();
    $.post(window.appPath + '/service/gettranslation/ajax.aspx', {
      translationTag: translationTag
    }, function (data) {
      if (data !== null) {
        deferred.resolve($('string', data).text());
      } else {
        return null;
      }
    }); // Example usage
    //window.getTranslation("translationTag").done(function (translation) {
    //    // Do something with 'translation' here
    //});

    return deferred.promise();
  };
}

if (!window.getTranslations) {
  window.getTranslations = function (translationTags) {
    var deferred = $.Deferred();
    $.post(window.appPath + '/service/gettranslations/ajax.aspx', {
      translationTags: translationTags
    }, function (data) {
      if (data !== null) {
        var translations = {};
        var responses = $('ArrayOfTranslationResponse TranslationResponse', data);

        for (var i = 0; i < responses.length; i++) {
          translations[$("Key", responses[i]).text()] = $("Value", responses[i]).text();
        }

        deferred.resolve(translations);
      } else {
        return null;
      }
    }); // Example usage - Array items are: { 'arrayItemReference': 'TranslationTag' }
    //var translations = [
    //            { "invalidEmailAddress": "Invalid email address" },
    //            { "pleaseTryAgain": "please try again" }
    //];
    //window.getTranslations(translations).done(function (data) {
    //    var invalidEmail = data.invalidEmailAddress + "";
    //    var pleaseTryAgain = data.pleaseTryAgain + "";
    //    // Do stuff
    //});

    return deferred.promise();
  };
}

if (!window.saveViConfiguration) {
  window.saveViConfiguration = function (sku, savedConfigId) {
    var deferred = $.Deferred();
    $.post(window.appPath + '/service/saveViConfiguration/ajax.aspx' + '?itemCode=' + sku + '&savedConfigId=' + savedConfigId, function (data) {
      deferred.resolve(data);
    });
    return deferred.promise();
  };
}

if (!window.addValveIslandToBasket) {
  window.addValveIslandToBasket = function (sku) {
    var deferred = $.Deferred();
    $.post(window.appPath + '/service/addValveIslandToBasket/ajax.aspx?', {
      sku: sku
    }, function (data) {
      deferred.resolve(data);
    });
    return deferred.promise();
  };
}

if (!window.addFRLItemToBasket) {
  window.addFRLItemToBasket = function (sku) {
    var deferred = $.Deferred();
    $.post(window.appPath + '/service/addfrltobasket/ajax.aspx?', {
      itemCode: sku
    }, function (data) {
      deferred.resolve(data);
    });
    return deferred.promise();
  };
}

if (!window.LoadMoreLister) {
  window.LoadMoreLister = function (additionalUrl, start_index, fetch_count) {
    var deferred = $.Deferred();
    $.post(window.appPath + '/api/fredhopper/Products', {
      url: additionalUrl,
      start_index: start_index,
      fetch_count: fetch_count
    }, function (data) {
      deferred.resolve(data);
    });
    return deferred.promise();
  };
}

if (!window.GetUserId) {
  window.GetUserId = function () {
    var deferred = $.Deferred();
    $.post('/' + window.gwsMc + '/' + window.gwsLc + '/service/GetUserId/ajax.aspx', function (data) {
      deferred.resolve(data);
    });
    return deferred.promise();
  };
}

function addToBasketPopupContent(contentType) {
  var deferred = $.Deferred();
  $.post('/' + _gwsMc + '/' + _gwsLc + '/service/AddToBasketPopupContent/ajax.aspx', function (data) {
    deferred.resolve(data);
  });
  return deferred.promise();
}

window.getUserInfo = function () {
  var deferred = $.Deferred();
  $.post('/' + window.gwsMc + '/' + window.gwsLc + '/service/GetUserInfo/ajax.aspx', function (data) {
    deferred.resolve(data);
  });
  return deferred.promise();
};