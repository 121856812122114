"use strict";

(function () {
  "use strict";

  angular.module("norgrenApp").controller("BaseDistributorController", function ($scope, $log, $controller, stockLocatorAjaxService, distributorStockService) {
    var main = main || $scope.$ctrl;
    angular.extend(this, $controller('BaseNorgrenController', {
      $scope: $scope
    }));

    main.init = function () {
      if (!distributorStockService.isDataLoaded()) {
        main.findStock({
          useLocation: !main.postCode
        });
      } else {
        main.finishResponse();
      }
    };

    main.findStock = function (options) {
      options = options || {
        useLocation: false
      };
      setTimeout(function () {
        stockLocatorAjaxService.getStockLocations(main.marketCode, main.cultureCode, main.itemCode, main.postCode, getCountryCode(), main.brand, options.useLocation).then(main.findStockResponse, main.findStockResponseError);
      });
    };

    function getCountryCode() {
      var selectedCountry = main.countries.find(function (c) {
        return c.CountryName === main.selectedCountry.trim();
      });
      return selectedCountry.CountryCode;
    }

    main.findStockResponse = function (response) {
      distributorStockService.setData(response.data);
      main.finishResponse();
    };

    main.findStockResponseError = function (response) {
      log("error");
      log(response);
      distributorStockService.setError("Could not find the location. Please use a different name or a valid post code.");
      distributorStockService.setDataIsLoaded(false);
      main.isLoading = false;
      main.data.hasError = distributorStockService.getIsError();
      main.data.errorMessage = distributorStockService.getErrorMessage();
    };

    main.finishResponse = function () {
      main.data = distributorStockService.getStockObjects();
      main.currentLocation = distributorStockService.getCurrentLocation();
      main.isLoading = false;
      main.postCode = "";
      main.currentItem = null;

      if (main.map !== undefined) {
        main.map.setCenter(main.currentLocation);
      }

      distributorStockService.setDataIsLoaded(true);
    };
  });
})();