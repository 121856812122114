"use strict";

(function () {
  "use strict"; //Want a singleton instance of the service so using the factory method.

  angular.module("norgrenApp").service("predictiveSearchAjaxService", ["$http", predictiveSearchAjaxService]);

  function predictiveSearchAjaxService($http) {
    var service = this;
    var apiEndpoint = window.appPath;

    service.predict = function (marketCode, searchTerm) {
      var request = {
        url: apiEndpoint + '/predictive.json',
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        params: {
          market: marketCode,
          term: searchTerm,
          _ac: new Date().getTime() //anti-cache

        }
      };
      return $http(request);
    };

    return service;
  }
})();