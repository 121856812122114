"use strict";

(function () {
  "use strict";

  angular.module("norgrenApp").service("translationsService", ["$q", "$sce", translationsService]);

  function translationsService($q, $sce) {
    var service = this;

    service.getTranslations = function (translationTags) {
      if (!Array.isArray(translationTags)) {
        return null;
      }

      var translationsToGet = [];
      translationTags.forEach(function (key, index) {
        var translation = {};
        translation[key] = key;
        translationsToGet.push(translation);
      });
      var deferred = $q.defer();
      window.getTranslations(translationsToGet).done(function (data) {
        var translations = {};
        translationTags.forEach(function (key, index) {
          translations[key] = data[key] ? $sce.trustAsHtml(data[key]) : key;
        });
        deferred.resolve(translations);
      });
      return deferred.promise;
    };

    service.loadTranslations = function (translationTags, translationKeys) {
      var translationsToGet = [];
      var deffered = $q.defer();
      translationKeys.forEach(function (item) {
        if (!translationTags.hasOwnProperty(item)) {
          translationsToGet.push(item);
        }
      });

      if (translationsToGet.length <= 0) {
        deffered.resolve(translationTags);
      }

      service.getTranslations(translationsToGet).then(function (data) {
        if (!translationTags.length) {
          translationTags = data;
        }

        deffered.resolve(translationTags);
      });
      return deffered.promise;
    };
  }
})();