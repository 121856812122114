"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

(function () {
  angular.module('norgrenApp').service("Locations", function ($http) {
    return InitialiseService($http);
  }).component("location", {
    templateUrl: "/mkt/ln/js/app/templates/location-template.html",
    controller: ["$http", '$sce', "$scope", "Locations", "$attrs", "$timeout", function ($http, $sce, $scope, Locations, $attrs, $timeout) {
      var main = $scope.$ctrl; //Intialise Variables

      main.countryLocations = [];
      main.europeanLocations = [];
      main.americanLocations = [];
      main.asainLocations = [];
      main.visibleLocations = [];
      main.buttons = [];
      main.emailText = "Email";
      main.telphoneText = "Tel";
      main.faxText = "Fax";
      main.mapLinkText = "Map Link";
      main.noPaddingBottom = false; //Intialise Functions

      main.showCountryLocations = function () {
        if (main.countryLocations.length <= 0) {
          Locations.getCountryLocations(main.countryUrl).then(function (result) {
            main.countryLocations = main.trustHtml(result.data);
            main.visibleLocations = main.countryLocations;
          });
        } else {
          main.visibleLocations = main.countryLocations;
        }
      };

      main.showEuropeanLocations = function () {
        if (main.europeanLocations.length <= 0) {
          Locations.getEuropeanLocations().then(function (result) {
            main.europeanLocations = main.trustHtml(result.data);
            main.visibleLocations = main.europeanLocations;
          });
        } else {
          main.visibleLocations = main.europeanLocations;
        }

        main.noPaddingBottom = true;
      };

      main.showAmericanLocations = function () {
        if (main.americanLocations.length <= 0) {
          Locations.getAmericanLocations().then(function (result) {
            main.americanLocations = main.trustHtml(result.data);
            main.visibleLocations = main.americanLocations;
          });
        } else {
          main.visibleLocations = main.americanLocations;
        }

        main.noPaddingBottom = true;
      };

      main.showAsainLocations = function () {
        if (main.asainLocations.length <= 0) {
          Locations.getAsianLocations().then(function (result) {
            main.asainLocations = main.trustHtml(result.data);
            main.visibleLocations = main.asainLocations;
          });
        } else {
          main.visibleLocations = main.asainLocations;
        }

        main.noPaddingBottom = true;
      };

      main.boostrapButtons = function () {
        var buttons = $attrs.buttons.split(",");

        for (var button in buttons) {
          var splitButton = buttons[button].split("|");

          if (splitButton.length > 1) {
            main.buttons.push({
              text: splitButton[2],
              action: splitButton[3]
            });
          }
        }
      };

      main.trustHtml = function (data) {
        for (var _i = 0, _Object$entries = Object.entries(data); _i < _Object$entries.length; _i++) {
          var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
              key = _Object$entries$_i[0],
              value = _Object$entries$_i[1];

          for (item in value) {
            if (!isEmptyOrSpaces(value[item].Address)) {
              value[item].Address = $sce.trustAsHtml(value[item].Address);
            }
          }
        }

        return data;
      };

      main.getTranslations = function () {
        window.getTranslation("Email").done(function (d) {
          //emailText = d;
          if (d) {
            main.emailText = d;
          }
        });
        window.getTranslation("Fax").done(function (d) {
          if (d) {
            main.faxText = d;
          }
        });
        window.getTranslation("Tel").done(function (d) {
          if (d) {
            main.telphoneText = d;
          }
        });
        window.getTranslation("Map Link").done(function (d) {
          if (d) {
            main.mapLinkText = d;
          }
        });
      }; //Run Controller Init Functions


      main.boostrapButtons();
      main.getTranslations();
    }]
  });

  function InitialiseService($http) {
    var service = {};

    service.getCountryLocations = function () {
      return $http.get(window.appPath + "/service/countrylocations/ajax.aspx?countrypath=" + "/" + window.appPath);
    };

    service.getEuropeanLocations = function () {
      return $http.get(window.appPath + "/service/europeanlocations/ajax.aspx");
    };

    service.getAmericanLocations = function () {
      return $http.get(window.appPath + "/service/americanlocations/ajax.aspx");
    };

    service.getAsianLocations = function () {
      return $http.get(window.appPath + "/service/asianlocations/ajax.aspx");
    };

    return service;
  }

  function isEmptyOrSpaces(str) {
    return str === null || str.match("/^\s*$/") !== null;
  }
})();