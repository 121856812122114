"use strict";

(function () {
  "use strict";

  angular.module("norgrenApp").component("searchBar", {
    controller: searchBarController,
    templateUrl: '/mkt/ln/js/app/templates/SearchBar/searchbar-template.html',
    bindings: {
      defaultTerm: '@',
      searchPage: '@'
    }
  });

  function searchBarController($scope, $window, $sce, $controller, predictiveSearchAjaxService) {
    angular.extend(this, $controller('BaseNorgrenController', {
      $scope: $scope
    })); // Set translations

    $scope.$ctrl.translationKeys = ['Products', 'Categories', 'Datasheets', 'Search terms'];
    $scope.$ctrl.loadTranslations($scope.$ctrl.translations, $scope.$ctrl.translationKeys);
    $scope.$ctrl.isLoaded = false;

    $scope.searchTermChanged = function () {
      if ($scope.searchTerm.length < 3) {
        $scope.$ctrl.isLoaded = false;
        return;
      }

      ; //alert($scope.searchTerm);

      predictiveSearchAjaxService.predict(window.gwsMc, $scope.searchTerm).then(function (response) {
        $scope.$ctrl.data = response.data;
        $scope.$ctrl.isLoaded = response.data.SearchTerms.length !== 0 || response.data.Products.length !== 0 || response.data.Categories.length !== 0 || response.data.Datasheets.length !== 0;
      });
    };

    $scope.search = function () {
      if ($scope.searchTerm !== $scope.$ctrl.defaultTerm && $scope.searchTerm !== "") {
        var searchTerm = $scope.searchTerm.replace(/^\s*/, '').replace(/\s*$/, '').replace(/\s+/g, '+').replace(/\//g, "_").replace('*', '');
        var landingUrl = $scope.$ctrl.searchPage + encodeURI(searchTerm);
        $window.location.href = landingUrl;
      }

      return false;
    };

    $scope.renderHtml = function (html_code) {
      return $sce.trustAsHtml(html_code);
    };
  }
})();