"use strict";

(function () {
  var motionProfilePreStep = "motion_profile_prestep";
  var resolvableStatusText = 'RESOLVABLE';
  var notOkStatusText = 'not OK';
  var tactonImageUrl = window.gwsCdnDomain + '/cpq/img/';
  var _noActuatorMotionProfileStepsFieldName = 'question_part_no_of_actuator_steps_field';

  var _cadLinkStart = window.gwsCadenasBaseDomain + '/3d-cad-models/?info=';

  var _cadLinkEnd = '&countryIso=' + window.gwsMcIso + '&languageIso=' + window.gwsLcIso + '&hidePortlets=navigation&amp;showPortlets=generation?cadenasControls=1&varset=';

  var app = angular.module("norgrenApp");
  app.value("$routerRootComponent", "mainapp"); // Directives

  app.directive('fancybox', fancyBoxDirective); ///
  /// Controllers
  ///

  app.controller("tactonBaseController", function ($http, $sce, $scope, $document, $attrs, $compile, $rootRouter, $window, $q, tactonAjaxService, tactonRootObject, tactonBomRootObject, $anchorScroll) {
    var _main$init, _main$LoadFirstStep;

    // Set variables
    var main = $scope.$ctrl;
    main.resetCount = 0; // Pull in translations that are already available in Head.ascx

    main.translations = [];
    main.translations.push("Loading");
    main.translations.push("Add to Basket");
    main.translations.push("Time (s)");
    main.translations.push("Position");
    main.translations.push("Speed");
    main.translations.push("Acceleration");
    main.translations.push("Position (mm)_graph");
    main.translations.push("Speed (m/s)_graph");
    main.translations.push("Acceleration (m/s)_graph");
    main.translations.push("Stroke length_tooltip_cpq");
    main.translations.push("Brake_tooltip_cpq");
    main.translations.push("Feedback_tooltip_cpq");
    main.translations.push("Spindle Support_tooltip_cpq");
    main.translations.push("Safety stroke per side_tooltip_cpq");
    main.translations.push("Max Torque_tooltip_cpq");
    main.translations.push("Max Velocity_tooltip_cpq");
    main.translations.push("RMS Torque_tooltip_cpq");
    main.translations.push("Lifetime L10_tooltip_cpq");
    main.translations.push("Max Force_tooltip_cpq");
    main.translations.push("USB Converter Cable_tooltip_cpq");
    main.translations.push("Connector Type_tooltip_cpq");
    main.translations.push("Supply Exhaust Ports Position_tooltip_cpq");
    main.translations.push("IP Rating_tooltip_cpq");
    main.translations["Loading"] = window.translation.Loading;
    main.translations["Add to Basket"] = window.translation.AddToBasket;
    main.translations["Time (s)"] = window.translation.TimeS;
    main.translations["Position"] = window.translation.Position;
    main.translations["Speed"] = window.translation.Speed;
    main.translations["Acceleration"] = window.translation.Acceleration;
    main.translations["Position (mm)_graph"] = window.translation.PositionMM;
    main.translations["Speed (m/s)_graph"] = window.translation.SpeedMS;
    main.translations["Acceleration (m/s)_graph"] = window.translation.AccelerationMS;
    main.translations["Stroke length_tooltip_cpq"] = window.translation.StrokeLengthTooltip;
    main.translations["Brake_tooltip_cpq"] = window.translation.BrakeTooltip;
    main.translations["Feedback_tooltip_cpq"] = window.translation.FeedbackTooltip;
    main.translations["Spindle Support_tooltip_cpq"] = window.translation.SpindleSupportTooltip;
    main.translations["Safety stroke per side_tooltip_cpq"] = window.translation.SafetyStrokeTooltip;
    main.translations["Max Torque_tooltip_cpq"] = window.translation.MaxTorqueTooltip;
    main.translations["Max Velocity_tooltip_cpq"] = window.translation.MaxVelocityTooltip;
    main.translations["RMS Torque_tooltip_cpq"] = window.translation.RMSTorqueTooltip;
    main.translations["Lifetime L10_tooltip_cpq"] = window.translation.LifetimeL10Tooltip;
    main.translations["Max Force_tooltip_cpq"] = window.translation.MaxForceTooltip;
    main.translations["USB Converter Cable_tooltip_cpq"] = window.translation.UsbConverterTooltip;
    main.translations["Connector Type_tooltip_cpq"] = window.translation.ConnectorTypeTooltip;
    main.translations["Supply Exhaust Ports Position_tooltip_cpq"] = window.translation.SupplyExhaustTooltip;
    main.translations["IP Rating_tooltip_cpq"] = window.translation.IPRatingTooltip; // Some values are numbers, and so tries to get the array at x position, we don't want that

    for (var i = 0; i < 100; i++) {
      main.translations.push("" + i + "");
      main.translations["" + i + ""] = "" + i + "";
    } //User Information for the request a quote


    main.userName = {
      text: $attrs.username
    };
    main.userTelephoneNumber = {
      text: $attrs.usertelephonenumber
    };
    main.userEmailAddress = {
      text: $attrs.useremailaddress
    };
    main.userCompanyName = {
      text: $attrs.usercompanyname
    };
    main.userAccountNumber = {
      text: $attrs.useraccountnumber
    };
    main.userAddressStreet1 = {
      text: $attrs.useraddressstreet1
    };
    main.userAddressStreet2 = {
      text: $attrs.useraddressstreet2
    };
    main.userCity = {
      text: $attrs.usercity
    };
    main.userPostcode = {
      text: $attrs.userpostcode
    };
    main.emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    main.comments = '';
    main.showLocalisedNumbers = $attrs.showlocalisednumbers !== undefined && $attrs.showlocalisednumbers.toLocaleLowerCase() === "true";

    main.getLocalisedDescription = function (description) {
      if (!description || description.length === undefined || description.length === 0) return description;
      return description.replace('.', ',');
    };

    main.revertLocalisedInput = function (input) {
      if (!input || input.length === 0) return input;
      return input.replace(',', '.');
    };

    main.productId = $attrs.productid;
    main.cookieName = $attrs.cookiename; //Step Names. Should all this go into config or db? //if only this were more dynamic to the logic

    main.motionProfilePreStep = "motion_profile_prestep";
    main.motionProfileStep = "motion_profile_step";
    main.configurationStep = "technical_configuration_step";
    main.accessoriesStep = "accessories_step"; //Motion Profile Pre Members

    main.motionProfileAngle = "motion_profile_part_angle_degree_field";
    main.motionProfileFriction = "motion_profile_part_friction_coeff_dropdown_field";
    main.topPartRuntimeHours = "top_part_runtime_hours_per_day_field";
    main.topPartRuntimeDays = "top_part_runtime_days_per_year_field";
    main.actuatorTypePartTypeField = "actuator_type_part_type_field";
    main.carriagePositionFieldName = "carriage_position_part_type_field";
    main.loadOffsetGroupFieldName = "load_offset_group"; //Motion Profile Groups and Members

    main.motionProfileGroup = "motion_profile_group";
    main.instanciateGroup = "instanciate_group";
    main.diagramDataGroup = "diagram_data_group";
    main.infoGroup = "info_group";
    main.motionProfileStepsContainer = "motion_profile_steps_container_group";
    main.motionProfileStepInput = "step_input_group";
    main.topPartActuatorSizingIsPossible = "top_part_actuator_sizing_is_possible_field"; //Configuration Groups

    main.techincalConfigurationGroup = "technical_configuration_group"; //Accessory Groups and Members

    main.mountingGroup = "mounting_group";
    main.accessoryGroup = "accessory_group";
    main.driveGroup = "drive_group";
    main.brakingResistorUndersized = "brakingResistorUndersized_field"; // Specialised fancybox functionality member name triggers

    main.motionProfilePrestepFancyBoxTriggerNames = [];
    main.motionProfilePrestepFancyBoxTriggerNames.push('carriage_position_part_type_field');
    main.motionProfilePrestepFancyBoxTriggerNames.push('load_offset_group');
    main.motionProfilePrestepFancyBoxTriggerNames.push('motion_profile_part_load_offset_z_mm_field');
    main.motionProfilePrestepFancyBoxTriggerNames.push('motion_profile_part_load_offset_x_mm_field');
    main.motionProfilePrestepFancyBoxTriggerNames.push('motion_profile_part_load_offset_y_mm_field'); // Preselect the product

    main.productOptions = [{
      name: "Cylinder",
      id: 1,
      value: main.productId
    }];
    main.productSelection = main.productOptions[0]; // Events / Button actions

    main.init = (_main$init = main.init) !== null && _main$init !== void 0 ? _main$init : function () {
      main.setNavigationClasses(); // Check whether we need to auto select an Actuator Type

      var actuatorTypeQueryString = getQueryStringByName('actuator-type');

      if (actuatorTypeQueryString) {
        main.actuatorType = actuatorTypeQueryString;
        log('Set main.actuatorType as ' + main.actuatorType);
      }

      var tactonCookie = main.getTactonCookie(main.cookieName);

      if (tactonCookie !== null) {
        // Check if user has come straight from link with querystring                         
        if (actuatorTypeQueryString) {
          // Ask user if they want to reset
          main.showResetFancyboxQueryString = true;
        }

        var now = new Date();
        var cookie = new Date(tactonCookie.createdDate);
        var diff = getDateDifferenceHours(cookie, now);
        log('Checking cookie age. Now: ' + now + '. Cookie createdDate: ' + cookie + '. Diff (hours): ' + diff);

        if (diff >= 1) {
          log('Showing optional reset configuration popup');
          main.showResetFancyboxOldCookie = true;
        }

        main.configState = tactonCookie.configState;
        main.cookieVersion = tactonCookie.modelVersion;

        if (main.configState === '') {
          main.configuratorStart();
        } else {
          main.completeTestCommit = true;
          main.LoadFirstStep(tactonCookie.step);
        }
      } else {
        main.configuratorStart();
      }
    };
    main.LoadFirstStep = (_main$LoadFirstStep = main.LoadFirstStep) !== null && _main$LoadFirstStep !== void 0 ? _main$LoadFirstStep : function (step) {
      if (step && step !== '') {
        log('Loading cookie step: ' + step);
        main.changeStep(step);
      } else {
        main.changeStep(motionProfilePreStep);
      }
    };

    main.configuratorStart = function () {
      if (main.productSelection.value === "" || main.productSelection.value.Length <= 0) {
        alert(main.translations['A product must be selected']); // TODO TACTON : Highlight input

        return;
      }

      main.createTactonCookie(main.cookieName);
      main.currentStepIndex = 0;
      setTimeout(function () {
        tactonAjaxService.ConfiguratorStart(main.productSelection.value, main.actuatorType).then(main.configuratorStartResponse, main.apiResponseError);
      }, 0);
    };

    main.changeStep = function (stepName) {
      if (main.currentStep !== null && stepName === main.currentStep.name) {
        return;
      }

      if (main.currentStep !== null && !main.partSizingPossible) {
        // Stop the user navigating forward when part sizing is not possible
        log('Part sizing not possible - blocked nav');

        if (stepName === main.configurationStep || stepName === main.accessoriesStep) {
          return;
        }
      }

      log('Changing to step ' + stepName);
      main.updateTactonCookie(main.cookieName, main.configState, stepName); // Handle page reload to BOM - nav is not initialised                        

      if (stepName === 'summary') {
        main.isBOMCookieLoad = true;
        stepName = main.accessoriesStep;
        log('isBOM - initially loading ' + stepName);
      }

      main.setDataLoading(true).then(function () {
        var currentStepIndex = 0;
        var newStepIndex = 0;

        for (var i = 0; i < main.navigationSteps.length; i++) {
          if (main.navigationSteps[i] !== null && main.currentStep !== null) {
            if (main.navigationSteps[i].name === stepName) {
              newStepIndex = i;
            }

            if (main.navigationSteps[i].name === main.currentStep.name) {
              currentStepIndex = i;
            }
          } else if (main.currentStep === null) {
            // This happens when we are on the BOM
            newStepIndex = main.navigationSteps.length - 1;
            currentStepIndex = main.navigationSteps.length - 1;
          }
        } // Is new stepName a higher or lower index?


        if (newStepIndex > currentStepIndex) {
          tactonAjaxService.ConfiguratorNext(stepName, main.configState, main.completeTestCommit, main.cookieVersion).then(main.configuratorStepResponse, main.apiResponseError).finally(function () {
            $anchorScroll();

            if (main.isBOMCookieLoad) {
              setTimeout(function () {
                main.showBOM();
              }, 0); // 0 timeout forces rendering to complete before changing to DOM
            }
          });
        } else if (newStepIndex < currentStepIndex) {
          tactonAjaxService.ConfiguratorPrev(stepName, main.configState, main.completeTestCommit, main.cookieVersion).then(main.configuratorStepResponse, main.apiResponseError).finally(function () {
            $anchorScroll();

            if (main.isBOMCookieLoad) {
              setTimeout(function () {
                main.showBOM();
              }, 0); // 0 timeout forces rendering to complete before changing to DOM
            }
          });
        } else {
          tactonAjaxService.ConfiguratorStep(stepName, main.configState, main.completeTestCommit, main.cookieVersion).then(main.configuratorStepResponse, main.apiResponseError).finally(function () {
            $anchorScroll();

            if (main.isBOMCookieLoad) {
              setTimeout(function () {
                main.showBOM();
              }, 0); // 0 timeout forces rendering to complete before changing to DOM
            }
          });
        } // Set the currentStepIndex for Next/Prev buttons


        main.currentStepIndex = newStepIndex;
      });
    };

    main.inputChanged = function (obj, $event, force) {
      log('inputChanged triggered');
      var fancyboxInput = null;

      if ($event && $event.target) {
        fancyboxInput = angular.element('.fancybox-inner .tacton-fancybox-content input[name="' + $event.target.name + '"]');
        var mainInput = angular.element('.inline-template .not-fancybox-member input[name="' + $event.target.name + '"]');
        obj.originalValue = mainInput.attr('data-originalValue');

        if (mainInput.hasClass('fancy-changed')) {
          obj.changed = mainInput.val();
          obj.fancyboxChanged = true;
        }
      }

      if (obj.changed !== null && obj.changed !== undefined) {
        // Check this is not in fancybox modal
        // That functionality is handled separately on the modal input - $(document).on('focusout', '.tacton-fancybox-content input', function ()
        log($event);

        if ($event) {
          log($event.target);

          if ($event.target) {
            log($event.target.closest('.tacton-fancybox-content'));

            if ($event && $event.target && $event.target.closest('.tacton-fancybox-content')) {
              log('Input change event is within fancybox - ignored. Handled directly from a trigger inside the fancybox');
              return;
            }
          }
        }

        var memberName = '';

        if (obj.member) {
          memberName = obj.member.name;
        } else {
          memberName = $event.target.name;
        }

        log('Updating ' + memberName + ' to ' + obj.changed);
        log('forced: ' + force);

        if (obj.changed === null || obj.changed === undefined || obj.changed.length === null || obj.changed.length === 0) {
          obj.changed = "0";
        }

        var data = {
          "par": memberName,
          "val": this.showLocalisedNumbers ? this.revertLocalisedInput(obj.changed) : obj.changed
        };
        main.lastCommitData = data;
        $("#ajax_loader_vi").show(); // call api to commit the value

        if (force) {
          tactonAjaxService.ConfiguratorAccept(data.par, data.val, main.configState).then(main.configuratorCommitResponse, main.apiResponseError).finally(function () {
            main.showNotification();

            if (obj.fancyboxChanged && main.changeRejected) {
              log('Reset val on our fb field. Orig val: ' + obj.originalValue);
              fancyboxInput.val(obj.originalValue);
            }

            if (main.inputFocusElementName && !obj.fancyboxChanged) {
              focusElement(main.inputFocusElementName);
            }

            $("#ajax_loader_vi").hide();
          });
        } else {
          tactonAjaxService.ConfiguratorCommit(data.par, data.val, main.configState).then(main.configuratorCommitResponse, main.apiResponseError).finally(function () {
            main.showNotification();

            if (obj.fancyboxChanged) {
              if (main.changeRejected) {
                log('Reset val on our fb field. Orig val: ' + obj.originalValue);
                fancyboxInput.val(obj.originalValue);
              }
            }

            if (main.inputFocusElementName && !obj.fancyboxChanged) {
              focusElement(main.inputFocusElementName);
            }

            $("#ajax_loader_vi").hide();
          });
        }
      }
    };

    main.showBOM = function () {
      if (!main.partSizingPossible) {
        log('Part sizing not possible - blocked nav');
        return;
      }

      log('Loading BOM');
      main.updateTactonCookie(main.cookieName, main.configState, 'summary', main.cookieVersion);
      main.setDataLoading(true).then(function () {
        // First navigate to final page to force Tacton to load all defaults
        var lastStep = main.navigationSteps[main.navigationSteps.length - 1].name;
        log(lastStep);
        log(main.currentStep.name);

        if (lastStep !== main.currentStep.name) {
          tactonAjaxService.ConfiguratorNext(lastStep, main.configState, main.completeTestCommit, main.cookieVersion).then(function (response) {
            // Update configState                                      
            var rootObject = tactonRootObject.build(response.data);
            main.configState = rootObject.configState;

            if (rootObject.steps[0].cadenasValue !== null) {
              main.cadLink = $sce.trustAsResourceUrl(_cadLinkStart + rootObject.steps[0].cadenasProjectPath + _cadLinkEnd + rootObject.steps[0].cadenasValue);
            }

            if (window.gwsLc.toLowerCase() === 'de-de' && rootObject.steps[0].datasheetDeFileName !== null) {
              main.datasheetLink = $sce.trustAsResourceUrl(window.gwsCdnDomain + '/pdf/' + rootObject.steps[0].datasheetDeFileName);
            }

            if (window.gwsLc.toLowerCase() !== 'de-de' && rootObject.steps[0].datasheetEnFileName !== null) {
              main.datasheetLink = $sce.trustAsResourceUrl(window.gwsCdnDomain + '/pdf/' + rootObject.steps[0].datasheetEnFileName);
            } // call api to get the BOM


            tactonAjaxService.ConfiguratorBom(main.configState, main.cookieVersion).then(main.configuratorBomResponse, main.apiResponseError);
          }, main.apiResponseError).finally(function () {
            $anchorScroll();

            if (main.isBOMCookieLoad) {
              main.isBOMCookieLoad = false;
              main.setConfigLoaded();
            }

            log('Finished loading BOM');
          });
        } else {
          // call api to get the BOM
          tactonAjaxService.ConfiguratorBom(main.configState, main.cookieVersion).then(main.configuratorBomResponse, main.apiResponseError).finally(function () {
            $anchorScroll();

            if (main.isBOMCookieLoad) {
              main.isBOMCookieLoad = false;
              main.setConfigLoaded();
            }

            log('Finished loading BOM');
          });
        }
      });
    };

    main.addMotionStep = function (motions) {
      main.inputChanged({
        member: {
          name: _noActuatorMotionProfileStepsFieldName
        },
        changed: motions + 1
      }, null);
    };

    main.removeMotionStep = function (motions) {
      main.inputChanged({
        member: {
          name: _noActuatorMotionProfileStepsFieldName
        },
        changed: motions - 1
      }, null, true);
    };

    main.padlockClick = function (member) {
      if (member.committed) {
        tactonAjaxService.ConfiguratorUnCommit(member.name, main.configState).then(main.configuratorCommitResponse, main.apiResponseError);
        return;
      }

      var value = "";

      if (member.properties.guitype !== 'text') {
        value = member.domain.elements.find(function (e) {
          return e.selected === true;
        }).name;
      } else {
        value = member.value;
      }

      main.inputChanged({
        member: {
          name: member.name
        },
        changed: value
      }, null);
    };

    main.reset = function () {
      // If cookie is older than an hour, a fancybox shows to promt the user to reset config.
      $.fancybox.close();
      log('main.resetCount (' + main.resetCount + ') <= main.maxAutoResets (' + main.maxAutoResets + ')');

      if (main.resetCount <= main.maxAutoResets) {
        var actuatorType = main.actuatorType;
        log('Auto reset');
        main.resetCount = main.resetCount + 1;
        main.deleteTactonCookie(main.cookieName);
        main.setInitialVariables();

        if (actuatorType) {
          main.actuatorType = actuatorType;
          log('Attempting to set Actuator Type to ' + main.actuatorType);
        }

        main.configuratorStart();
      } else {
        log('Manual reset');
        main.error = true;
        main.resetCount = 0;
      }
    };

    main.changeStepButton = function (direction) {
      var increment = 0;

      switch (direction) {
        case "next":
          if (main.nextButtonDisabled) return;
          increment = 1; //Stop Double Clicks

          main.nextButtonDisabled = true;
          break;

        case "prev":
          if (main.prevButtonDisabled) return;
          increment = -1; //Stop Double Clicks

          main.prevButtonDisabled = true;
          break;
      } //If we dont have a current step name. Presume we are at the sumary page.


      if (main.currentStep === null) {
        main.currentStepIndex = main.navigationSteps.length - 1;
      } else {
        main.currentStepIndex = main.navigationSteps.findIndex(function (s) {
          return s.name === main.currentStep.name;
        }) + increment;
      } // Handle the Summary page


      if (main.currentStepIndex === main.navigationSteps.length) {
        main.showBOM();
      } else {
        main.changeStep(main.navigationSteps[main.currentStepIndex].name);
      }
    };

    main.submitQuote = function () {
      main.requestAQuoteError = null;
      log('Called submitQuote');

      if (!main.userName.text || !main.userTelephoneNumber.text || !main.userEmailAddress.text || !main.userCompanyName.text || !main.quantity.text) {
        main.requestAQuoteError = main.translations["Validation error summary"];
        return;
      }

      var leadsource = "website-" + window.gwsMc;
      var productNumber = "";

      if (main.bomRootObject) {
        productNumber = main.bomRootObject.bom[0].description;
      }

      var comments = '';

      if (main.comments.text && main.comments.text !== '') {
        comments = main.comments.text;
      }

      log('Submitting: ' + ' ' + main.userName.text + ' ' + main.userTelephoneNumber.text + ' ' + main.userEmailAddress.text + ' ' + main.userCompanyName.text + ' ' + main.userAccountNumber.text + ' ' + main.userAddressStreet1.text + ' ' + main.userAddressStreet2.text + ' ' + main.userCity.text + ' ' + main.userPostcode.text + ' ' + main.quantity.text + ' ' + productNumber + ' ' + leadsource + ' ' + comments + ' ' + window.gwsMc);
      tactonAjaxService.ConfiguratorLead(main.configState, main.userName.text, main.userTelephoneNumber.text, main.userEmailAddress.text, main.userCompanyName.text, main.userAccountNumber.text, main.userAddressStreet1.text, main.userAddressStreet2.text, main.userCity.text, main.userPostcode.text, productNumber, leadsource, comments, main.quantity.text).then(function () {
        $.fancybox.open($("#request-a-quote-dialog-thankyou"));
      }, main.apiResponseError);
    };

    $scope.sanitize = function (tooltip) {
      return $sce.trustAsHtml(tooltip);
    }; // Response Functions


    main.configuratorStartResponse = function (response) {
      main.initiatePage(response);
      main.updateTactonCookie(main.cookieName, main.configState, main.currentStep.name, main.cookieVersion);
    };

    main.configuratorStepResponse = function (response) {
      if (main.completeTestCommit === true) {
        main.completeTestCommit = false;
      }

      main.initiatePage(response);
      main.updateTactonCookie(main.cookieName, main.configState, main.currentStep.name, main.cookieVersion);
    };

    main.configuratorCommitResponse = function (response) {
      main.initiatePage(response);
      main.updateTactonCookie(main.cookieName, main.configState, main.currentStep.name, main.cookieVersion);
    };

    main.configuratorBomResponse = function (response) {
      main.initiateBOM(response);
      main.updateTactonCookie(main.cookieName, main.configState, 'summary', main.cookieVersion);
    };

    main.apiResponseError = function (response) {
      if (main.completeTestCommit === true) {
        main.completeTestCommit = false;
      } // If an error occurs reset automatically - do not inform the user


      main.reset();
      log("error");
      log(response);
    }; // Functions


    main.initiatePage = function (response) {
      var rootObject = tactonRootObject.build(response.data);
      main.checkCookieVersion(rootObject);

      if (!rootObject) {
        return;
      }

      var modelTranslations = [{
        Key: "From"
      }, {
        Key: "To"
      }];
      main.loadTranslations(modelTranslations);
      main.loadTranslations(rootObject.translations);
      main.navigationSteps = buildNavigationSteps(response.data.steps);
      main.currentStep = rootObject.steps[0];
      main.configState = rootObject.configState;
      main.carriagePositionValue = main.currentStep.carriagePositionValue;

      if (main.currentStep.cadenasValue !== null) {
        main.cadLink = $sce.trustAsResourceUrl(_cadLinkStart + main.currentStep.cadenasProjectPath + _cadLinkEnd + main.currentStep.cadenasValue);
      }

      if (window.gwsLc.toLowerCase() === 'de-de' && main.currentStep.datasheetDeFileName !== null) {
        main.datasheetLink = $sce.trustAsResourceUrl(window.gwsCdnDomain + '/pdf/' + rootObject.steps[0].datasheetDeFileName);
      }

      if (window.gwsLc.toLowerCase() !== 'de-de' && main.currentStep.datasheetEnFileName !== null) {
        main.datasheetLink = $sce.trustAsResourceUrl(window.gwsCdnDomain + '/pdf/' + rootObject.steps[0].datasheetEnFileName);
      }

      main.getCurrentStepIndex();
      main.handleNextPrevButtons();
      main.displayMotionStepGraphData();
      main.setNavigationClasses();
      main.handlePartSizingNotPossible();
      main.updateConfigurationCalculations();
      main.handleEngineerRequiredToOrderBrakingResistorUndersized();
      main.changeRejected = false;
      log(rootObject.response);

      if (rootObject.response !== null) {
        var changed = rootObject.response.changed; // Check for RESOLVEABLE/'not OK'

        if (rootObject.response.status === resolvableStatusText) {
          log(resolvableStatusText); // If this is a Motion Profile step being added, automatically allow it through

          if (main.isMotionProfileStepAdded(rootObject.response)) {
            log('Motion Profile Step detected - Automatically accepting'); // Accept forces original commit value plus all 'changed' are applied

            tactonAjaxService.ConfiguratorAccept(main.lastCommitData.par, main.lastCommitData.val, main.configState).then(main.configuratorCommitResponse, main.apiResponseError).finally(function () {
              $("#dialog-message").dialog("close");
            });
          } else {
            buildResolvableDialogHtml(changed, main.translations);
            DisplayDialog("#resolvable-dialog", {
              maxWidth: "420px",
              width: "420px",
              dialogClass: "resolvable-dialog",
              buttons: [{
                text: main.translations['Accept'],
                "class": "btn btn-default",
                click: function click() {
                  log('Accepting'); // Accept forces original commit value plus all 'changed' are applied

                  tactonAjaxService.ConfiguratorAccept(main.lastCommitData.par, main.lastCommitData.val, main.configState).then(main.configuratorCommitResponse, main.apiResponseError).finally(function () {
                    $("#resolvable-dialog").dialog("close");
                  });
                }
              }, {
                text: main.translations["Reject"],
                "class": "btn btn-dark-grey",
                click: function click() {
                  log('Rejecting'); // Don't need to do anything

                  $("#resolvable-dialog").dialog("close");
                }
              }]
            });
          }
        }

        if (rootObject.response.status === notOkStatusText) {
          log(notOkStatusText);
          main.changeRejected = true;
        }
      }

      if (!main.configLoaded && !main.isBOMCookieLoad) {
        log('Setting config loaded');
        main.setConfigLoaded();
      }

      var tactonCookie = null;

      if (!main.showResetFancyboxQueryString && main.showResetFancyboxOldCookie) {
        // Don't change to false if we are trying to load summary / BOM
        tactonCookie = main.getTactonCookie(main.cookieName);

        if (tactonCookie.step !== 'summary') {
          setTimeout(function () {
            $.fancybox.open($("#reset-configurator-dialog-old-cookie"));
          }, 0); // Only show once

          main.showResetFancybox = false;
          main.showResetFancyboxOldCookie = false; // Reset the cookie createdDate

          main.updateTactonCookie(main.cookieName, null, null, null, true);
        }
      }

      if (main.showResetFancyboxQueryString) {
        // Don't change to false if we are trying to load summary / BOM
        tactonCookie = main.getTactonCookie(main.cookieName);

        if (tactonCookie.step !== 'summary') {
          setTimeout(function () {
            $.fancybox.open($("#reset-configurator-dialog-querystring"));
          }, 0); // Only show once

          main.showResetFancyboxQueryString = false; // Reset the cookie createdDate

          main.updateTactonCookie(main.cookieName, null, null, null, true);
        }
      }

      main.setDataLoading(false);
      main.bomRootObject = null;
      log(main.navigationSteps);
      log(main.currentStep);
    };

    main.initiateBOM = function (response) {
      main.bomRootObject = tactonBomRootObject.build(response.data);
      main.checkCookieVersion(main.bomRootObject);

      if (!main.bomRootObject) {
        return;
      }

      main.loadTranslations(main.bomRootObject.translations);
      main.currentStep = null;
      main.currentStepIndex = main.navigationSteps.length;
      main.handleNextPrevButtons();
      main.handleEngineerRequiredToOrderBOMIsEto();
      main.setNavigationClasses();
      main.setDataLoading(false);
      main.configLoaded = true;
      var tactonCookie = null;

      if (!main.showResetFancyboxQueryString && main.showResetFancyboxOldCookie) {
        // Don't change to false if we are trying to load summary / BOM
        tactonCookie = main.getTactonCookie(main.cookieName);

        if (tactonCookie.step !== 'summary') {
          setTimeout(function () {
            $.fancybox.open($("#reset-configurator-dialog-old-cookie"));
          }, 0); // Only show once

          main.showResetFancybox = false; // Reset the cookie createdDate

          main.updateTactonCookie(main.cookieName, null, null, null, true);
        }
      }

      if (main.showResetFancyboxQueryString) {
        // Don't change to false if we are trying to load summary / BOM
        tactonCookie = main.getTactonCookie(main.cookieName);

        if (tactonCookie.step !== 'summary') {
          setTimeout(function () {
            $.fancybox.open($("#reset-configurator-dialog-querystring"));
          }, 0); // Only show once

          main.showResetFancyboxQueryString = false; // Reset the cookie createdDate

          main.updateTactonCookie(main.cookieName, null, null, null, true);
        }
      }

      log(main.bomRootObject);
    };

    main.setDataLoading = function (showLoadingScreen) {
      var deferred = $q.defer();

      if (showLoadingScreen) {
        $('.tacton-overlay').fadeIn(200, function () {
          deferred.resolve();
        });
      } else {
        setTimeout(function () {
          $('.tacton-overlay').fadeOut(200, function () {
            deferred.resolve();
          });
        }, 201);
      }

      return deferred.promise;
    };

    main.handleNextPrevButtons = function () {
      // Enable / Disable Next and Prev buttons
      if (main.currentStepIndex <= 0) {
        main.prevButtonDisabled = true;
        main.prevButtonPossible = false;
      } else {
        main.prevButtonDisabled = false;
        main.prevButtonPossible = true;
      } // Note: main.navigationSteps.length is zero based so should be length-1, however we have the Summary page and so do not apply -1


      if (main.currentStepIndex >= main.navigationSteps.length) {
        main.nextButtonDisabled = true;
        main.nextButtonPossible = false;
      } else {
        main.nextButtonDisabled = false;
        main.nextButtonPossible = true;
      }

      log('next: ' + main.nextButtonDisabled);
      log('prev: ' + main.prevButtonDisabled);
    };

    main.formatCDNImageName = function (name, large) {
      // lowercase
      name = name.toLowerCase(); // replace spaces with underscores

      name = name.replace(new RegExp(' ', 'g'), '_'); // replace / with nothing

      name = name.replace(new RegExp('/', 'g'), ''); // if none, change to mount_none

      if (name === 'none') {
        name = 'mount_none';
      }

      var imageUrl = tactonImageUrl + name;

      if (large) {
        imageUrl = imageUrl + '-large';
      }

      return imageUrl + '.png';
    };

    main.showMotionStepAdd = function (motion) {
      return main.currentStep.motionProfileMaxStepCount > motion;
    };

    main.showMotionStepRemove = function (motion) {
      // Tacton issue https://projects.zoho.com/portal/igentics1#buginfo/415504000007914061/415504000007975335
      if (main.currentStep.motionProfileMinStepCount > 2) {
        return true;
      }

      return main.currentStep.motionProfileMinStepCount < motion;
    };

    main.displayMotionStepGraphData = function () {
      if (main.currentStep.name !== main.motionProfileStep) {
        return;
      } // Get our data element


      var diagramDataMember = main.currentStep.rootGroup.members.find(function (m) {
        return m.name === "diagram_data_group";
      }).members.find(function (m) {
        return m.name === "top_part_diagramData_cpq_field";
      }); // Set the graph options

      main.positionOptions = createGraphOptions(main.translations['Position'], main.translations['Time (s)'], main.translations['Position (mm)_graph'], main.showLocalisedNumbers);
      main.speedOptions = createGraphOptions(main.translations['Speed'], main.translations['Time (s)'], main.translations['Speed (m/s)_graph'], main.showLocalisedNumbers);
      main.accelerationOptions = createGraphOptions(main.translations['Acceleration'], main.translations['Time (s)'], main.translations['Acceleration (m/s)_graph'], main.showLocalisedNumbers); // Add graph data

      if (diagramDataMember && diagramDataMember.value !== '') {
        main.processStepData(diagramDataMember.value);
      }
    };

    main.processStepData = function (data) {
      var steps = JSON.parse(data);
      steps = calculateTimeAndVelocity(steps);
      main.updateDataset(createPositionProfile(steps), "Position");
      main.updateDataset(createSpeedProfile(steps), "Speed");
      main.updateDataset(createAccelerationProfile(steps), "Acceleration");
    };

    main.updateDataset = function (data, label, color) {
      var dataset;

      if (label === 'Position') {
        dataset = findDatasetByLabel(label, main.positionData.datasets);
      }

      if (label === 'Speed') {
        dataset = findDatasetByLabel(label, main.speedData.datasets);
      }

      if (label === 'Acceleration') {
        dataset = findDatasetByLabel(label, main.accelerationData.datasets);
      }

      if (color === undefined) {
        color = 'rgba(183, 18, 52, 1)';
      }

      if (dataset === undefined) {
        dataset = {
          label: label,
          borderWidth: 2,
          borderColor: color,
          fill: false,
          tension: 0,
          data: []
        };

        if (label === 'Position') {
          main.positionData.datasets.push(dataset);
        }

        if (label === 'Speed') {
          main.speedData.datasets.push(dataset);
        }

        if (label === 'Acceleration') {
          main.accelerationData.datasets.push(dataset);
        }
      }

      dataset.data = data;
    };

    main.showNotification = function () {
      var status = "";
      var text = "";

      if (main.changeRejected) {
        status = "rejected";
        text = main.translations["Value rejected message"];
      } else {
        status = "success";
        text = main.translations["Value changed message"];
      }

      if (text !== undefined) {
        showNotification(text, status);
      }
    };

    main.handlePartSizingNotPossible = function () {
      // Check top_part_actuator_sizing_is_possible_field is yes
      if (main.currentStep.name !== main.motionProfileStep) {
        return;
      } // Get our element


      var partSizingPossibleMember = main.currentStep.rootGroup.members.find(function (m) {
        return m.name === main.infoGroup;
      }).members.find(function (m) {
        return m.name === main.topPartActuatorSizingIsPossible;
      });
      log(partSizingPossibleMember);

      if (partSizingPossibleMember) {
        if (partSizingPossibleMember.value !== 'Yes') {
          main.partSizingPossible = false;
          main.nextButtonDisabled = true;
          main.nextButtonPossible = false;
        } else {
          main.partSizingPossible = true;
          main.nextButtonDisabled = false;
          main.nextButtonPossible = true;
        }
      }
    };

    main.handleEngineerRequiredToOrderBrakingResistorUndersized = function () {
      if (main.currentStep.name !== main.accessoriesStep) {
        return;
      }

      var engineerRequiredToOrder = false;
      var infoGroupMembers = main.currentStep.rootGroup.members.find(function (m) {
        return m.name === main.infoGroup;
      });
      if (infoGroupMembers == null) return;
      var engineerRequiredMember = infoGroupMembers.members.find(function (m) {
        return m.name === main.brakingResistorUndersized;
      });
      log(engineerRequiredMember);

      if (engineerRequiredMember) {
        if (engineerRequiredMember.value !== 'Yes') {
          engineerRequiredToOrder = false;
        } else {
          engineerRequiredToOrder = true;
        }
      }

      if (engineerRequiredToOrder) {
        main.engineerRequiredToOrderBrakingResistorUndersized = true;
        main.enableAddToBasket = false;
      } else {
        main.engineerRequiredToOrderBrakingResistorUndersized = false;

        if (window.gwsCpqAtb && window.gwsIsLoggedIn) {
          main.enableAddToBasket = true;
        }
      }
    };

    main.handleEngineerRequiredToOrderBOMIsEto = function () {
      var isEto = false;
      log(main.bomRootObject);

      if (main.bomRootObject) {
        main.bomRootObject.bom.forEach(function (bom, index) {
          if (searchForIsEto(bom)) {
            isEto = true;
          }
        });
      }

      log(isEto);

      if (isEto) {
        main.engineerRequiredToOrderBOMIsEto = true;
      } else {
        main.engineerRequiredToOrderBOMIsEto = false;
      }
    };

    main.getTranslations = function (keys) {
      var deferred = $q.defer(); // Build our keys the way multiple translations function expects it

      var translations = [];
      keys.forEach(function (key, index) {
        var translation = {};
        translation[key] = key;
        translations.push(translation);
      });
      window.getTranslations(translations).done(function (data) {
        keys.forEach(function (key, index) {
          $scope.$apply(function () {
            main.translations[key] = $sce.trustAsHtml(data[key]) || key;
          });

          if (index === keys.length - 1) {
            //resolve the promise after the final translation is complete
            deferred.resolve();
          }
        });
      });
      return deferred.promise;
    };

    main.loadTranslations = function (translationTags) {
      var newTags = [];
      translationTags.forEach(function (item) {
        if (!main.translations.hasOwnProperty(item.Key)) {
          if (item.Value) {
            main.translations[item.Key] = $sce.trustAsHtml(item.Value) || item.Key;
          } else {
            newTags.push(item.Key);
          }
        }
      });

      if (newTags.length > 0) {
        main.getTranslations(newTags);
      }
    };

    main.setNavigationClasses = function () {
      if (main.currentStep === null) {
        // We are on the BOM / Summary 
        for (var i = 0; i < main.navigationSteps.length; i++) {
          main.navigationSteps[i].cssClass = 'done';
        }
      }

      if (main.bomRootObject === null) {
        // We are on a step
        buildNavigationSteps(main.navigationSteps);
      }
    };

    main.isMotionProfileStepAdded = function (response) {
      var changed = response.changed[0];

      if (changed.name === _noActuatorMotionProfileStepsFieldName) {
        var oldValue = parseInt(changed.oldValue);
        var newValue = parseInt(changed.newValue);

        if (newValue > oldValue) {
          return true;
        }
      }

      return false;
    };

    main.keyPressHandler = function (e) {
      // Ignore enter key as it triggers the enewsletter signup default button
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    main.getCurrentStepIndex = function () {
      for (var i = 0; i < main.navigationSteps.length; i++) {
        if (main.navigationSteps[i] !== null && main.currentStep !== null) {
          if (main.navigationSteps[i].name === main.currentStep.name) {
            main.currentStepIndex = i;
          }
        } else if (main.currentStep === null) {
          // This happens when we are on the BOM
          main.currentStepIndex = main.navigationSteps.length - 1;
        }
      }
    };

    main.setConfigLoaded = function () {
      setTimeout(function () {
        $('#tacton-configurator-loading-container').hide();
        $('#tacton-configurator-loading-container .tacton-overlay').remove();
      }, 0);
      main.configLoaded = true;
    };

    main.checkCookieVersion = function (rootObject) {
      log('checkCookieVersion - rootObject.currentVersion:' + rootObject.currentVersion + '. main.cookieVersion:' + main.cookieVersion);
      var currentVersion = rootObject.currentVersion; // Handle if no version returned from GWS api

      if (!currentVersion) {
        main.cookieVersion = main.cookieVersion;
      } // If already exists - check cookie version


      if (main.cookieVersion !== '' && main.cookieVersion !== currentVersion) {
        log('Cookie version mismatch - resetting'); // Reset

        main.reset();
      } // Set the cookie if not set


      if (main.cookieVersion === '' && currentVersion) {
        main.cookieVersion = currentVersion;
      }
    };

    main.getMember = function (members, memberName) {
      var member = null;

      if (members.length === 0) {
        log('No members to find member ' + memberName);
        return member;
      }

      member = members.find(function (m) {
        return m.name === memberName;
      });

      if (!member) {
        log('Could not find member ' + memberName);
      }

      log('found member');
      log(member);
      return member;
    };

    main.getMemberFieldValue = function (group, fieldName) {
      var returnValue = '';

      if (group.members.length === 0) {
        log('No members in group to find fieldvalue ' + fieldName);
        return returnValue;
      }

      var field = group.members.find(function (m) {
        return m.name === fieldName;
      });

      if (field) {
        returnValue = field.value;
      } else {
        log('Could not find fieldvalue ' + fieldName);
      }

      return returnValue;
    };

    main.parseIntAndRound = function (value, decimals) {
      var returnValue = 0;

      if (Number.isNaN(Number.parseFloat(value))) {
        return returnValue;
      }

      return Number.parseFloat(value).toFixed(decimals);
    };

    main.calculateLifetime = function (kmPerYearFieldValue) {
      return Math.floor(kmPerYearFieldValue * 2);
    };

    main.getLifetimeCapabilityText = function (lifetime) {
      if (lifetime === "10000") {
        return '> 10000';
      } else {
        return lifetime;
      }
    };

    main.updateConfigurationCalculations = function () {
      if (main.currentStep.name !== main.configurationStep) {
        return;
      } // Set the infoGroup


      var infoGroup = main.getMember(main.currentStep.rootGroup.members, main.infoGroup); // Check if we need to fall back to the rodless variants of variable names
      // There is nowhere in the Tacton response for this step that tells us it is a 'Rodless' configuration

      var kmPerYearFieldName = 'km_per_year_field';
      var kmPerYearFieldValue = main.getMemberFieldValue(infoGroup, kmPerYearFieldName);
      var lifetimeL10FieldName = 'lifetime_l10_field';
      var lifetimeL10FieldValue = main.getMemberFieldValue(infoGroup, lifetimeL10FieldName);

      if (lifetimeL10FieldValue === '') {
        lifetimeL10FieldName = 'lifetime_l10_field_rodless';
        lifetimeL10FieldValue = main.getMemberFieldValue(infoGroup, lifetimeL10FieldName);
      }

      var axialForceFieldName = 'axial_force_cmb_part_force_N_max_based_on_stroke_bore_pitch_field';
      var axialForceFieldValue = main.getMemberFieldValue(infoGroup, axialForceFieldName);

      if (axialForceFieldValue === '') {
        axialForceFieldName = 'axial_force_rodless_cmb_part_force_N_max_based_on_stroke_bore_pitch_field';
        axialForceFieldValue = main.getMemberFieldValue(infoGroup, axialForceFieldName);
      }

      var velocityFieldName = 'velocity_cmb_part_velocity_mm_s_t1000_max_based_on_stroke_bore_pitch_field';
      var velocityFieldValue = main.getMemberFieldValue(infoGroup, velocityFieldName);

      if (velocityFieldValue === '') {
        velocityFieldName = 'velocity_rodless_cmb_part_velocity_mm_s_t1000_max_based_on_stroke_bore_pitch_field';
        velocityFieldValue = main.getMemberFieldValue(infoGroup, velocityFieldName);
      }

      main.sizing_part_force_N_max_calculated_field = main.parseIntAndRound(main.getMemberFieldValue(infoGroup, 'sizing_part_force_N_max_calculated_field'), 0);
      main.axial_force_cmb_part_force_N_max_based_on_stroke_bore_pitch_field = main.parseIntAndRound(axialForceFieldValue, 0);
      main.sizing_part_velocity_m_s_max_t1000_calculated_field = main.parseIntAndRound(main.getMemberFieldValue(infoGroup, 'sizing_part_velocity_m_s_max_t1000_calculated_field'), 3);
      main.velocity_cmb_part_velocity_mm_s_t1000_max_based_on_stroke_bore_pitch_field = main.parseIntAndRound(velocityFieldValue, 3);
      main.top_part_motionProfileTorqueMax_field = main.parseIntAndRound(main.getMemberFieldValue(infoGroup, 'top_part_motionProfileTorqueMax_field'), 3);
      main.intermittentMotorMaxTorque_field = main.parseIntAndRound(main.getMemberFieldValue(infoGroup, 'intermittentMotorMaxTorque_field'), 3);
      main.top_part_motionProfileTorqueRMS_field = main.parseIntAndRound(main.getMemberFieldValue(infoGroup, 'top_part_motionProfileTorqueRMS_field'), 3);
      main.continuousMaxTorque_field = main.parseIntAndRound(main.getMemberFieldValue(infoGroup, 'continuousMaxTorque_field'), 3);
      main.calculatedLifetime = main.calculateLifetime(kmPerYearFieldValue);
      main.lifetimeCapabilityText = main.getLifetimeCapabilityText(main.parseIntAndRound(lifetimeL10FieldValue, 0));
    };

    main.calculateColumns = function (childMemberCount) {
      var result = 12 / childMemberCount;
      if (result < 4) return 4;
      return result;
    };

    main.isMotionProfilePrestepFancyBoxTrigger = function (name) {
      return main.motionProfilePrestepFancyBoxTriggerNames.indexOf(name) >= 0;
    };

    main.showTcUnit = function (tcUnit) {
      if (tcUnit) {
        return '(' + tcUnit + ')';
      }
    };

    main.isConfigurationStep = function () {
      if (main.currentStep.name === main.configurationStep) {
        return true;
      }

      return false;
    };

    main.isAccessoriesStep = function () {
      if (main.currentStep.name === main.accessoriesStep) {
        return true;
      }

      return false;
    };

    main.isIgnoredMember = function (member) {
      if (member.properties.hiddenAsBool || member.properties.hideFromLeadGenAsBool || member.properties.infoGroupAsBool) {
        return true;
      }

      return false;
    }; //Cookie Functions. Cookies currently set to last for 8760 hours or 1 yr


    main.updateTactonCookie = function (cookieName, configState, step, cookieVersion, resetCreatedDate) {
      var rawCookieValue = getGwsCookie(cookieName);

      if (!rawCookieValue) {
        main.createTactonCookie(cookieName);
        rawCookieValue = getGwsCookie(cookieName);
      }

      var cookieValueObject = JSON.parse(rawCookieValue); //Update Cookie

      if (step) {
        cookieValueObject.step = step;
      }

      if (configState) {
        cookieValueObject.configState = configState;
      }

      if (cookieVersion) {
        cookieValueObject.modelVersion = cookieVersion;
      }

      if (!cookieValueObject.createdDate || resetCreatedDate) {
        cookieValueObject.createdDate = new Date();
      }

      rawCookieValue = JSON.stringify(cookieValueObject);
      setCookie(cookieName, rawCookieValue, 8760);
    };

    main.createTactonCookie = function (cookieName) {
      var cookieValue = JSON.stringify({
        configState: main.configState,
        step: '',
        modelVersion: main.cookieVersion,
        createdDate: Date
      }); //Set cookie to last 8760 hours, or 2.7626e+14 milliseconds.

      setCookie(cookieName, cookieValue, 8760);
    };

    main.deleteTactonCookie = function (cookieName) {
      deleteCookie(cookieName);
    };

    main.getTactonCookie = function (cookieName) {
      var rawCookieValue = getGwsCookie(cookieName);

      if (rawCookieValue === "") {
        return null;
      }

      var cookieValueObject = JSON.parse(rawCookieValue);

      if (!cookieValueObject.createdDate) {
        main.updateTactonCookie();
      }

      return cookieValueObject;
    };

    main.setInitialVariables = function () {
      main.currentStep = null;
      main.bomRootObject = null;
      main.configState = '';
      main.navigationSteps = [];
      main.configLoaded = false;
      main.error = false;
      main.currentStepIndex = 0;
      main.nextButtonDisabled = false;
      main.prevButtonDisabled = true;
      main.prevButtonPossible = false;
      main.nextButtonPossible = true;
      main.lastCommitData = null;
      main.cpqToolTipAppendStr = window.cpqToolTipAppendStr;
      main.cadLink = '';
      main.datasheetLink = '';
      main.changeRejected = false;
      main.inputFocusElementName = '';
      main.enableAddToBasket = window.gwsCpqAtb && window.gwsIsLoggedIn;
      main.partSizingPossible = true;
      main.engineerRequiredToOrderBOMIsEto = false;
      main.engineerRequiredToOrderBrakingResistorUndersized = false;
      main.isBOMCookieLoad = false;
      main.completeTestCommit = false;
      main.cookieVersion = '';
      main.positionData = {
        datasets: []
      };
      main.speedData = {
        datasets: []
      };
      main.accelerationData = {
        datasets: []
      };
      main.maxAutoResets = 5;
      main.showResetFancyboxOldCookie = false;
      main.showResetFancyboxQueryString = false;
      main.actuatorType = '';
    }; //Initialise the controller


    main.setInitialVariables();
    main.init();
  }); ///
  /// END Controllers
  ///
  // Components

  app.component("tactonMainController", {
    controller: ["$http", "$sce", "$scope", "$controller", '$document', "$attrs", "$compile", "$rootRouter", "$window", "$q", "tactonAjaxService", "tactonRootObject", "tactonBomRootObject", '$anchorScroll', function ($http, $sce, $scope, $controller, $document, $attrs, $compile, $rootRouter, $window, $q, tactonAjaxService, tactonRootObject, tactonBomRootObject, $anchorScroll) {
      angular.extend(this, $controller("tactonBaseController", {
        $scope: $scope,
        $attrs: $attrs
      }));
    }],
    templateUrl: "/mkt/ln/js/app/templates/tacton/mainController-template.html"
  }); ///
  /// BEGIN Directives
  ///

  function fancyBoxDirective() {
    return {
      restrict: 'A',
      link: function link(scope, element, attrs) {
        if ($(element).hasClass('tacton-download-btn')) {
          $(element).fancybox({
            type: 'iframe',
            modal: false,
            height: 600,
            width: 600,
            openEffect: 'none',
            closeEffect: 'none',
            centerOnScroll: true,
            'autoSize': false
          });
        }

        if ($(element).hasClass('tacton-request-a-quote-btn')) {
          $(element).fancybox();
        }

        if ($(element).hasClass('motion-profile-prestep-fancybox-trigger')) {
          $(element).fancybox({
            afterShow: function afterShow() {
              if ($(this)[0].element.attr('data-fancybox-class')) {
                $('.fancybox-skin').addClass($(this)[0].element.attr('data-fancybox-class'));
              }
            }
          });
        }
      }
    };
  } ///
  /// END Directives
  ///

})(); //
// JQUERY FUNCTIONS
//


var productsAddedToBasket = 0;
$(document).ready(function () {
  $('#cpq-basket-lightbox').fancybox({
    'hideOnOverlayClick': false,
    'beforeShow': function beforeShow() {
      var products = [],
          createElem = function createElem(elClass, eltext) {
        var element = document.createElement("div");
        element.className = elClass;
        element.innerHTML = eltext;
        return element;
      };

      $("#cpq-basket-checker .row-container").empty();
      $(".tacton-summary-table .tacton-summary-row").each(function () {
        var partno = $(".tacton-summary-item-part-number", this).text();
        var quantity = parseInt($(".tacton-summary-item-quantity", this).text());
        if (isNaN(quantity)) quantity = 1;

        if (partno.length > 0) {
          products.push({
            "partNo": partno,
            "quantity": quantity
          });
        }
      });
      log('products');
      log(products);
      var pandaItems = [];
      Array.prototype.forEach.call(products, function (product) {
        var row = createElem("row cpq-basket-item", "");
        row.appendChild(createElem("col-xs-3", product.partNo));
        row.appendChild(createElem("col-xs-3 quantity", product.quantity));
        row.appendChild(createElem("col-xs-3 leadtime", window.translation.Queued));
        row.appendChild(createElem("col-xs-3 unitprice text-right", ""));
        $("#cpq-basket-checker .row-container").append(row);
        var item = {
          domRow: row,
          productInfo: product
        };
        pandaItems.push(item);
      });

      if (pandaItems.length === 0) {
        return;
      }

      loopBasketItemsPandA(0, pandaItems);
    }
  });
}); // Must use 'on' due to dynamically creating the DOM via AngularJs

$(document).on('click', '.cpq-basket-trigger', function () {
  $("a#cpq-basket-lightbox").trigger('click');
  log('atb triggered');
  return false;
});
$(document).on('click', '.expand-table-toggle', function () {
  $(this).toggleClass('expand').parent().find(".tacton-calculation-table-container").slideToggle(100, function () {
    //setTimeout(function () { }, 1000)
    $(this).toggleClass('expand').parent().find(".tacton-calculation-table-container").css("overflow", "visible");
  });
  var i = $(this).find('i');

  if (i) {
    if ($(this).hasClass('expand')) {
      i.removeClass('fa-chevron-down').addClass('fa-chevron-up');
    } else {
      i.removeClass('a-chevron-up').addClass('fa-chevron-down');
    }
  }
});
$(document).on('change', '.tacton-fancybox-content input', function () {
  log('Fancybox input changed triggered');
  var fancyboxElement = $(this);
  var inputName = fancyboxElement.attr('name');
  var mainElement = null;

  if (fancyboxElement.attr('type') === 'radio') {
    log('radio'); // To allow the selected value to show correctly we had to use a different name on fancybox radio buttons

    inputName = inputName.substr(0, inputName.length - 9); //-fancybox is 9 chars

    var inputValue = fancyboxElement.attr('value');
    var mainElements = $(document).find('.not-fancybox-member input[name="' + inputName + '"]');
    mainElement = mainElements.filter('[value="' + inputValue + '"]');
    mainElement.attr('checked', true).trigger('click');
  } else {
    mainElement = $(document).find('.not-fancybox-member input[name="' + inputName + '"]');
    mainElement.focus();
    mainElement.attr('data-originalValue', mainElement.val());
    mainElement.val(fancyboxElement.val());
    mainElement.addClass('fancy-changed');
    mainElement.blur();
  }
}); //
// Functions
//

function buildResolvableDialogHtml(changed, translations) {
  var title = translations['Conflicted Values'],
      resolveableMessage = translations['Conflict Resolvable Message']; // Popup to show value to change

  var dialog = $('#resolvable-dialog');

  if (dialog === null || dialog === undefined || dialog.length === null || dialog.length === 0) {
    var container = $('.tacton-configurator-section');
    container.append($('<div />').attr('id', 'resolvable-dialog').attr('title', title));
    dialog = $('#resolvable-dialog');
  } // Reset dialog


  dialog.html('');
  var wrapper = $("<div class='tacton-configurator-section no-min-height'> </div>");

  for (var i = 0; i < changed.length; i++) {
    if (i > 0) {
      wrapper.append($('<hr />'));
    }

    wrapper.append($('<h4 class="tacton-modal-label"> </h4>').text(translations[changed[i].description]));
    wrapper.append($('<h5 class="tacton-modal-label"> </h5>').text(translations['From'] + ': ').append($('<em />').text(translations[changed[i].oldValueDescription])));
    wrapper.append($('<h5 class="tacton-modal-label"> </h5>').text(translations['To'] + ': ').append($('<em />').text(translations[changed[i].newValueDescription])));
  }

  dialog.append(wrapper);
  var message = $('<p class="intro-text" />').text(resolveableMessage);
  dialog.prepend(message);
}

function buildNavigationSteps(data) {
  var steps = [];
  var afterCurrent = false;

  for (var i = 0; i < data.length; i++) {
    // Use copy to create a new object
    var stepCopy = angular.copy(data[i]); // We don't need the rootGroup - lets not pass more data around than we need

    stepCopy.rootGroup = null;

    if (stepCopy.current) {
      afterCurrent = true;
      stepCopy.cssClass = 'active';
    } else {
      if (afterCurrent) {
        stepCopy.cssClass = 'off';
      } else {
        stepCopy.cssClass = 'done';
      }
    }

    steps.push(stepCopy);
  }

  return steps;
}

function loopBasketItemsPandA(index, pandaItems) {
  var item = pandaItems[index];
  getCpqBasketPriceAndAvailability(item.domRow, item.productInfo).done(function () {
    if (index + 1 < pandaItems.length) {
      loopBasketItemsPandA(index + 1, pandaItems);
    }
  });
}

function getCpqBasketPriceAndAvailability(container, product) {
  var deferred = $.Deferred(); //Start progress animation for context row

  var quantityElement = $('.quantity', container),
      leadTimeElement = $(".leadtime", container),
      unitPriceElement = $(".unitprice", container);
  leadTimeElement.html("");
  leadTimeElement.addClass("busy"); //Query the web service

  window.getPriceAndAvailabilityForQty(product.partNo, product.quantity).done(function (data) {
    if (data !== null) {
      var Status = $('Status', data).text();
      leadTimeElement.removeClass("busy");

      if (Status === 'OK') {
        //Use quantity from the response, as it could be different
        quantityElement.html($('Quantity', data).text());
        leadTimeElement.html($('LeadTimeText', data).text()); //Only display a price if one exists

        var priceText = $('UnitPriceFormatted', data).text();

        if (priceText !== null && priceText.length > 0) {
          unitPriceElement.html(priceText);
        }

        quantityElement.attr("readonly", false); //add to basket

        window.getAddToBasketResultWithPandA(product.partNo, product.quantity).done(function (data) {
          UpdateBasketDisplay($("BasketText", data).text());
          productsAddedToBasket++;
          deferred.resolve();
        });
      } else {
        leadTimeElement.addClass("invalid");
        leadTimeElement.html(window.translation.Invalid);
        deferred.resolve();
      }
    } else {
      deferred.resolve();
    }
  });
  return deferred.promise();
}

function focusElement(elementName) {
  setTimeout(function () {
    var input = $("input[name='" + elementName + "']");

    if (input.length === 0) {
      input = $("select[name='" + elementName + "']");
    }

    if (input.length > 1) {
      if (input.attr('type') === 'radio') {
        input = input.filter(':checked');
      }
    }

    input.focus();
  }, 0); // setTimeout to 0, so it is queued to trigger after DOM loaded
}

function createPositionProfile(steps) {
  var profile = [];
  var accumulatedTime = 0;
  profile.push({
    x: 0,
    y: 0
  });
  steps.forEach(function (step) {
    accumulatedTime += step.time;
    profile.push({
      x: accumulatedTime,
      y: step.position
    });

    if (step.pause > 0) {
      accumulatedTime += step.pause;
      profile.push({
        x: accumulatedTime,
        y: step.position
      });
    }
  });
  return profile;
}

function createSpeedProfile(steps) {
  var rounding = 1000;
  var profile = [];
  var accumulatedTime = 0;
  profile.push({
    x: 0,
    y: 0
  });
  steps.forEach(function (step) {
    var maxVelocity = step.velocities.v2;
    var t = step.times.t2;

    if (step.distance < 0) {
      maxVelocity = -step.velocities.v2;
    }

    accumulatedTime += Math.abs(step.times.t1);
    profile.push({
      x: timeRound(accumulatedTime, rounding),
      y: timeRound(maxVelocity, rounding)
    });
    accumulatedTime += Math.abs(t);
    profile.push({
      x: timeRound(accumulatedTime, rounding),
      y: timeRound(maxVelocity, rounding)
    });
    accumulatedTime += Math.abs(step.times.t3);
    profile.push({
      x: timeRound(accumulatedTime, rounding),
      y: timeRound(step.velocities.v4, rounding)
    });

    if (step.times.t4 > 0) {
      accumulatedTime += Math.abs(step.times.t4);
      profile.push({
        x: timeRound(accumulatedTime, rounding),
        y: timeRound(step.velocities.v4, rounding)
      });
    }
  });
  return profile;
}

function createAccelerationProfile(steps) {
  var rounding = 1000;
  var profile = [];
  var accumulatedTime = 0;
  profile.push({
    x: 0,
    y: 0
  });
  steps.forEach(function (step) {
    var t = step.times.t2;
    var a1 = step.acceleration;
    var a2 = step.deacceleration;

    if (step.distance < 0) {
      a1 = step.deacceleration;
      a2 = step.acceleration;
    }

    profile.push({
      x: timeRound(accumulatedTime, rounding),
      y: timeRound(a1, rounding)
    });
    accumulatedTime += Math.abs(step.times.t1);
    profile.push({
      x: timeRound(accumulatedTime, rounding),
      y: timeRound(a1, rounding)
    });
    profile.push({
      x: timeRound(accumulatedTime, rounding),
      y: 0
    });
    accumulatedTime += Math.abs(t);
    profile.push({
      x: timeRound(accumulatedTime, rounding),
      y: 0
    });
    profile.push({
      x: timeRound(accumulatedTime, rounding),
      y: timeRound(a2, rounding)
    });
    accumulatedTime += Math.abs(step.times.t3);
    profile.push({
      x: timeRound(accumulatedTime, rounding),
      y: timeRound(a2, rounding)
    });

    if (step.times.t4 > 0) {
      profile.push({
        x: timeRound(accumulatedTime, rounding),
        y: 0
      });
      accumulatedTime += Math.abs(step.times.t4);
      profile.push({
        x: timeRound(accumulatedTime, rounding),
        y: 0
      });
    }
  });
  return profile;
}

function calculateTimeAndVelocity(steps) {
  steps.forEach(function (step) {
    step.acceleration = Math.abs(step.acceleration);
    step.deacceleration = Math.abs(step.deacceleration) * -1;
    step.maxSpeed = step.acceleration * (step.time / 3);
    step.velocities = {
      v1: step.maxSpeed / 2,
      v2: step.maxSpeed,
      v3: step.maxSpeed / 2,
      v4: 0
    };
    var t1 = timeRound((step.velocities.v2 - step.velocities.v4) / step.acceleration);
    var t3 = timeRound((step.velocities.v4 - step.velocities.v2) / step.deacceleration);
    step.times = {
      t1: t1,
      t2: timeRound((Math.abs(step.distance) / 1000 - (0.5 * step.acceleration * Math.pow(t1, 2) + (step.velocities.v2 * t3 + 0.5 * step.deacceleration * Math.pow(t3, 2)))) / step.velocities.v2),
      t3: t3,
      t4: step.pause
    };
  });
  return steps;
}

function timeRound(value, rounding) {
  if (rounding === undefined) rounding = 1000;
  return Math.round(rounding * value) / rounding;
}

function findDatasetByLabel(label, datasets) {
  var foundDataset = undefined;
  datasets.forEach(function (dataset) {
    if (dataset.label === label) {
      foundDataset = dataset;
    }
  });
  return foundDataset;
}

function createGraphOptions(title, xAxesLabel, yAxisLabel, showLocalisedNumbers) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    title: {
      display: true,
      text: title
    },
    scales: {
      xAxes: [{
        type: 'linear',
        ticks: {
          beginAtZero: true,
          callback: function callback(label, index, labels) {
            return showLocalisedNumbers === true ? label.toString().replace('.', ',') : label;
          }
        },
        scaleLabel: {
          display: true,
          labelString: xAxesLabel
        }
      }],
      yAxes: [{
        ticks: {
          callback: function callback(label, index, labels) {
            return showLocalisedNumbers === true ? label.toString().replace('.', ',') : label;
          }
        },
        scaleLabel: {
          display: true,
          labelString: yAxisLabel
        }
      }]
    }
  };
}

function searchForIsEto(bom) {
  var returnBool = false;

  if (bom.attributes.isEto) {
    if (bom.attributes.isEto === 'true') {
      returnBool = true;
    }
  }

  if (bom.subItems.length > 0) {
    bom.subItems.forEach(function (subItem, index) {
      if (searchForIsEto(subItem)) {
        returnBool = true;
      }
    });
  }

  return returnBool;
}

function getDateDifferenceHours(dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff));
} //# sourceURL=tacton-component.js