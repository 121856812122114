"use strict";

(function () {
  "use strict";

  angular.module("norgrenApp").controller('StockLocatorController1', ["$http", "$scope", "$modalInstance", "stockLocatorAjaxService", "$controller", "NgMap", "postCode", "marketCode", "cultureCode", "itemCode", "itemImage", "itemDescription", "countryCode", "showStock", function ($http, $scope, $modalInstance, stockLocatorAjaxService, $controller, NgMap, postCode, marketCode, cultureCode, itemCode, itemImage, itemDescription, countryCode, showStock) {
    angular.extend(this, $controller('BaseDistributorController', {
      $scope: $scope
    }));
    var main = $scope.$ctrl;
    main.postCode = postCode;
    main.marketCode = marketCode;
    main.cultureCode = cultureCode;
    main.itemCode = itemCode;
    main.itemImage = itemImage;
    main.itemDescription = itemDescription;
    main.countryCode = countryCode;
    main.itemPrice = angular.element(document.querySelector(".price-val"))[0].textContent;

    if (showStock) {
      main.showStock = main.showStock === 'true';
    } else {
      main.showStock = true;
    }

    main.isLoading = true;
    main.showAddressBar = false;
    main.data = [];
    main.currentLocation = [];
    main.map = null;
    main.googleMapHighlighters = [];
    main.translationKeys = ["Search", "item", "In Stock", "CLOSE", "Find Stock", "Enter your City, State or Zip to update the results", "Update location", "Cancel", "Tel", "Email", "Add to basket", "More Information", "Or", "Use current location", "Website"];
    main.currentItem = null;

    main.initGoogleMaps = function () {
      main.loadTranslations();
      NgMap.getMap("map").then(function (map) {
        main.map = map;
        main.init();
      });
    };

    main.findStockForLocation = function () {
      setTimeout(function () {
        stockLocatorAjaxService.getStockLocations(main.marketCode, main.cultureCode, main.itemCode, main.postCode, main.countryCode, main.brand, true).then(main.findStockResponse, main.findStockResponseError);
      });
    };

    main.showDetails = function (e, stock) {
      main.currentItem = stock;
      main.map.setCenter({
        lat: stock.location[0],
        lng: stock.location[1]
      });
      main.map.setZoom(14);
    };

    main.close = function () {
      $modalInstance.close();
    };

    main.initGoogleMaps();
  }]);
})();