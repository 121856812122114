"use strict";

(function () {
  "use strict";

  angular.module("norgrenApp").service("stockLocatorAjaxService", ["$http", "$q", "$sce", stockLocatorAjaxService]);

  function stockLocatorAjaxService($http, $q, $sce) {
    var apiEndpont = "/api/StockLocator/";
    var service = this;

    service.getStockLocations = function (marketCode, cultureCode, productCode, postCode, countryCode, brand, useLocation) {
      var request = {
        url: apiEndpont + "GetStockLocations",
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        data: {
          marketCode: marketCode,
          productCode: productCode,
          postCode: postCode,
          cultureCode: cultureCode,
          countryCode: countryCode,
          brand: brand,
          useLocation: useLocation
        }
      };
      return $http(request);
    };
  }
})();