'use strict';

(function () {
  try {
    angular.module('norgrenApp').service("tactonAjaxService", ["$http", tactonAjaxService]);
  } catch (err) {
    console.log("tactonAjax services FAILED to load | " + err);
  }

  function tactonAjaxService($http) {
    var apiEndpoint = "/api/Tacton/";
    var service = this;

    service.ConfiguratorStart = function (productCode, actuatorType) {
      if (!actuatorType) {
        actuatorType = '';
      }

      var request = {
        url: apiEndpoint + "ConfiguratorStart",
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        data: {
          marketCode: window.gwsMc,
          cultureCode: window.gwsLc,
          product: productCode,
          configState: "",
          actuatorType: actuatorType
        }
      };
      return $http(request);
    };

    service.ConfiguratorCommit = function (par, val, configState) {
      var request = {
        url: apiEndpoint + "ConfiguratorCommit",
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        data: {
          marketCode: window.gwsMc,
          cultureCode: window.gwsLc,
          par: par,
          val: val,
          configState: configState
        }
      };
      return $http(request);
    };

    service.ConfiguratorAccept = function (par, val, configState) {
      var request = {
        url: apiEndpoint + "ConfiguratorAccept",
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        data: {
          marketCode: window.gwsMc,
          cultureCode: window.gwsLc,
          par: par,
          val: val,
          configState: configState
        }
      };
      return $http(request);
    };

    service.ConfiguratorUnCommit = function (par, configState) {
      var request = {
        url: apiEndpoint + "ConfiguratorUnCommit",
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        data: {
          marketCode: window.gwsMc,
          cultureCode: window.gwsLc,
          par: par,
          configState: configState
        }
      };
      return $http(request);
    };

    service.ConfiguratorStep = function (stepName, configState, completeTestCommit, cookieVersion) {
      // If completeTestCommit is not provided, force it to false
      log('completeTestCommit is ' + completeTestCommit);

      if (!completeTestCommit) {
        completeTestCommit = false;
      }

      log('completeTestCommit is now ' + completeTestCommit); // Handle cookieVersion not being sent

      if (!cookieVersion) {
        cookieVersion = '';
      }

      var request = {
        url: apiEndpoint + "ConfiguratorStep",
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        data: {
          marketCode: window.gwsMc,
          cultureCode: window.gwsLc,
          step: stepName,
          configState: configState,
          completeTestCommit: completeTestCommit,
          cookieVersion: cookieVersion
        }
      };
      return $http(request);
    };

    service.ConfiguratorNext = function (stepName, configState, completeTestCommit, cookieVersion) {
      // If completeTestCommit is not provided, force it to false
      log('completeTestCommit is ' + completeTestCommit);

      if (!completeTestCommit) {
        completeTestCommit = false;
      }

      log('completeTestCommit is now ' + completeTestCommit); // Handle cookieVersion not being sent

      if (!cookieVersion) {
        cookieVersion = '';
      }

      var request = {
        url: apiEndpoint + "ConfiguratorNext",
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        data: {
          marketCode: window.gwsMc,
          cultureCode: window.gwsLc,
          step: stepName,
          configState: configState,
          completeTestCommit: completeTestCommit,
          cookieVersion: cookieVersion
        }
      };
      return $http(request);
    };

    service.ConfiguratorPrev = function (stepName, configState, completeTestCommit, cookieVersion) {
      // If completeTestCommit is not provided, force it to false
      log('completeTestCommit is ' + completeTestCommit);

      if (!completeTestCommit) {
        completeTestCommit = false;
      }

      log('completeTestCommit is now ' + completeTestCommit); // Handle cookieVersion not being sent

      if (!cookieVersion) {
        cookieVersion = '';
      }

      var request = {
        url: apiEndpoint + "ConfiguratorPrev",
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        data: {
          marketCode: window.gwsMc,
          cultureCode: window.gwsLc,
          step: stepName,
          configState: configState,
          completeTestCommit: completeTestCommit,
          cookieVersion: cookieVersion
        }
      };
      return $http(request);
    };

    service.ConfiguratorBom = function (configState, cookieVersion) {
      // Handle cookieVersion not being sent
      if (!cookieVersion) {
        cookieVersion = '';
      }

      var request = {
        url: apiEndpoint + "ConfiguratorBom",
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        data: {
          marketCode: window.gwsMc,
          cultureCode: window.gwsLc,
          configState: configState,
          cookieVersion: cookieVersion
        }
      };
      return $http(request);
    };

    service.ConfiguratorLead = function (configState, name, telephoneNumber, emailAddress, companyName, accountNumber, addressStreet1, addressStreet2, city, postcode, product, leadSource, comments, quantity) {
      var request = {
        url: apiEndpoint + "ConfiguratorLead",
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        data: {
          marketCode: window.gwsMc,
          countryCode: window.gwsMc,
          configState: configState,
          contactName: name,
          company: companyName,
          contactEmail: emailAddress,
          contactPhone: telephoneNumber,
          erpAccountNumber: accountNumber + '',
          addressStreet1: addressStreet1 + '',
          addressStreet2: addressStreet2 + '',
          addressCity: city + '',
          addressZip: postcode + '',
          productOfInterest: product + '',
          leadSource: leadSource + '',
          comments: comments + '',
          quantity: quantity
        }
      };
      return $http(request);
    };

    return service;
  }
})(); //# sourceURL=tactonAjax.service.js