"use strict";

(function () {
  "use strict";

  function createDistributorStockFactories() {
    this.stockObjectListFactory = function (stockObject) {
      function StockObjectList(stockObjects, currentLocation) {
        this.stockObjects = stockObjects;
        this.currentLocation = currentLocation;
      }

      StockObjectList.build = function (data) {
        if (data === null) {
          return null;
        }

        var dataStock = data.Stock;
        var stockObjects = [];

        for (var i = 0; i < dataStock.length; i++) {
          stockObjects.push(stockObject.build(dataStock[i], i));
        }

        var currentLocation = {
          lat: data.CurrentLocation.Latitude,
          lng: data.CurrentLocation.Longitude,
          City: data.CurrentLocation.City,
          county: data.CurrentLocation.County,
          postCode: data.CurrentLocation.PostCode
        };
        return new StockObjectList(stockObjects, currentLocation);
      };

      return StockObjectList;
    };

    this.stockObjectFactory = function (stockAddress) {
      function StockObject(itemCode, freeStock, location, address, lastUpdate, phone, fax, emailAddress, distance, id, website, pos) {
        this.itemCode = itemCode;
        this.freeStock = freeStock;
        this.location = location;
        this.address = address;
        this.lastUpdate = lastUpdate;
        this.phone = phone;
        this.fax = fax;
        this.emailAddress = emailAddress;
        this.distance = distance;
        this.id = id;
        this.pos = pos;
        this.website = website;
      }

      StockObject.build = function (data, pos) {
        if (data === null) {
          return null;
        }

        var location = null;
        var address = null;

        if (data.Location !== null) {
          location = createLocation(data.Location);
        }

        if (data.Address !== null) {
          address = stockAddress.build(data.Address);
        }

        return new StockObject(data.ItemCode, data.FreeStock, location, address, data.LastUpdate, data.Phone, data.Fax, data.EmailAddress, data.Distance, data.Id, data.Website, pos);
      };

      return StockObject;
    };

    this.stockAddressFactory = function () {
      function StockAddress(companyName, addressLine1, addressLine2, city, state, stateCode, postCode) {
        this.companyName = companyName;
        this.addressLine1 = addressLine1;
        this.addressLine2 = addressLine2;
        this.city = city;
        this.state = state;
        this.postCode = postCode || stateCode;
      }

      StockAddress.build = function (data) {
        if (data === null) {
          return null;
        }

        return new StockAddress(data.CompanyName, data.AddressLine1, data.AddressLine2, data.City, data.State, data.StateCode, data.PostCode);
      };

      return StockAddress;
    };

    function createLocation(data) {
      return [data.Latitude, data.Longitude];
    }
  }

  var factories = new createDistributorStockFactories();
  angular.module("norgrenApp").factory("stockObjectList", ["stockObject", factories.stockObjectListFactory]);
  angular.module("norgrenApp").factory('stockAddress', factories.stockAddressFactory);
  angular.module("norgrenApp").factory('stockObject', ['stockAddress', factories.stockObjectFactory]);
})();