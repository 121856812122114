"use strict";

(function () {
  function libarySearch($scope) {
    var main = $scope.$ctrl;
    main.searchTerm = "";
  }

  angular.module('norgrenApp').component('librarySearch', {
    templateUrl: '/mkt/ln/js/app/templates/ContentItemsLister/library-search-template.html',
    controller: libarySearch,
    bindings: {
      search: '<'
    }
  });
})();