"use strict";

(function () {
  var mySwiper;
  angular.module("norgrenApp").run(function ($log, $rootScope, $http, $templateCache) {
    $rootScope.updateSwiper = function () {
      mySwiper.update();
    };
  });
  angular.module("norgrenApp").directive("swiperDirective", ["$rootScope", function ($rootScope) {
    return {
      restrict: "A",
      controller: function controller() {
        this.ready = function () {
          $rootScope.updateSwiper();
        };
      },
      link: function link(scope, element, attrs, ctrl) {
        mySwiper = new Swiper(element, {
          slidesPerView: 4,
          spaceBetween: 15,
          autoHeight: true,
          pagination: {
            el: element.find(".swiper-pagination"),
            clickable: true
          },
          breakpoints: {
            // when window width is >= 1000px
            1000: {
              slidesPerView: 2,
              slidesPerGroup: 1,
              spaceBetween: 27
            },
            // when window width is >= 575px
            575: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 24
            }
          }
        });
      }
    };
  }]);
  angular.module("norgrenApp").directive("swiperSlide", [function () {
    return {
      restrict: "A",
      require: "^swiperDirective",
      link: function link(scope, element, attrs, ctrl) {
        if (scope.$last) {
          ctrl.ready();
        }
      }
    };
  }]);
})();