"use strict";

(function () {
  var app = angular.module("norgrenApp");

  function tactonViController($http, $sce, $scope, $document, $attrs, $controller, $compile, $rootRouter, $window, $q, tactonAjaxService, tactonRootObject, tactonBomRootObject, $anchorScroll) {
    var main = $scope.$ctrl;

    main.LoadFirstStep = function () {
      main.changeStep('input_long_code_step');
    };

    main.init = function () {
      var rawCookieValue = getGwsCookie(main.cookieName);

      if (rawCookieValue !== "") {
        var tactonViCookie = JSON.parse(rawCookieValue);

        if (!tactonViCookie.createdDate) {
          main.updateTactonCookie("vicCookie", null, null, null, true);
        }

        main.configState = tactonViCookie.configState;
        main.cookieVersion = tactonViCookie.modelVersion;

        if (main.configState === '') {
          main.configuratorStart();
        } else {
          main.completeTestCommit = true;
          main.LoadFirstStep(tactonViCookie.step);
        }
      } else {
        main.configuratorStart();
      }
    }; //vi tacton steps


    main.steps = ["input_long_code_step", "control_option_step", "manifold_step", "stations_step", "accessories_step"];
    main.family = '';
    main.configCode = '';
    main.shortCode;

    main.isStep = function (step) {
      if (main.currentStep == null) {
        console.log(main);
        return false;
      }

      return main.currentStep.name === step;
    };

    $scope.$on('event_FamilySet', function (event, data) {
      main.family = data.family;
      main.configCode = data.configCode;
    });
    $scope.$on('event_NavigationSet', function (event, data) {
      if (main.family != "VR10" && main.family != "VR15" || !main.configState) {
        return;
      }

      if (data.data === main.steps.length) {
        if (main.currentStep) // because we call this twice we need to prevent the showBom step from calling twice
          main.showBOM();
      } else {
        var step = main.steps[data.data];

        if (step) {
          main.changeStep(step);
        }
      }
    });
    $scope.$on('event_productCodeSet', function (event, data) {
      main.shortCode = data;
    });
    $scope.$on('event_StartTactonVi', function (event, data) {
      main.productId = data.productId;
      main.cookieName = data.cookieName;
      angular.extend(this, $controller("tactonBaseController", {
        $scope: $scope,
        $attrs: $attrs
      }));
      var parentStepResponse = main.configuratorStepResponse;

      main.configuratorStepResponse = function (response) {
        parentStepResponse(response);

        if (main.currentStep.name == 'control_option_step') {
          var changedObg = {
            changed: main.family,
            member: {
              name: "range_part_range_field"
            }
          };
          main.inputChanged(changedObg);
        }

        main.updateTactonCookie(main.cookieName, main.configState, main.currentStep.name, main.cookieVersion);
        $scope.$emit('event_NavigationChangeEvent', {
          data: {
            index: main.currentStepIndex
          }
        });
      };

      var parentBomResponse = main.configuratorBomResponse;

      main.configuratorBomResponse = function (response) {
        parentBomResponse(response);
        var bomTranslations = [{
          Key: "Your configuration"
        }, {
          Key: "Valve Island"
        }, {
          Key: "Station"
        }, {
          Key: "Stations"
        }, {
          Key: "ISEM"
        }];
        main.loadTranslations(bomTranslations); //inform VI that the currentNavIndex is updated

        var index = 5;
        $scope.$emit('event_NavigationChangeEvent', {
          data: {
            index: index
          }
        });

        if (main.bomRootObject) {
          main.configCode = main.bomRootObject.bom.find(function (bom) {
            return bom.name === "options_BOM_group_comp";
          }).attributes.longCode;

          if (main.isPartRetrieval === true) {
            $scope.$emit('event_IsValidPartRetrieval', main.configCode);
            main.isPartRetrieval = false;
          }

          $scope.$emit('update_longcode', main.configCode);
        }

        main.updateTactonCookie(main.cookieName, main.configState, 'summary', main.cookieVersion); //we need to convert the existing list of parts into Products we can PandA

        var Products = [];

        function ConvertBomEntrytoProducts(tactonBom) {
          if (tactonBom.subItems) {
            tactonBom.subItems.forEach(ConvertBomEntrytoProducts);
          }

          if (tactonBom.attributes.componentCode) {
            var Product = {
              PartNumber: tactonBom.attributes.componentCode,
              Quantity: tactonBom.qty,
              IsAssemblyProduct: false,
              RetrievePandA: !tactonBom.attributes.hideInPricing,
              AllowZeroPrice: tactonBom.attributes.allowZeroPrice
            };
            Products.push(Product);
          }
        }

        function GetStationCount(tactonBom) {
          var stationCount = tactonBom.filter(function (bom) {
            return bom.name === "stations_BOM_group_comp";
          }).reduce(function (reducer, curr) {
            return reducer + Number.parseInt(curr.qty);
          }, 0);
          return stationCount;
        }

        function GetShortCode(tactonBom, stationCount) {
          var protocol = tactonBom.find(function (bom) {
            return bom.name === "options_BOM_group_comp";
          }).subItems.find(function (bom) {
            return bom.attributes.uniqueComponentId === "Control node";
          });
          var shortCode = main.configCode.substring(0, 4) + stationCount.toString().padStart(2, "0") + protocol.attributes.protocol_code + "00";
          return shortCode;
        }

        main.bomRootObject.bom.forEach(ConvertBomEntrytoProducts);
        main.stationCount = GetStationCount(main.bomRootObject.bom[0].subItems);
        var shortCode = GetShortCode(main.bomRootObject.bom, main.stationCount);
        $scope.$emit('event_Price', {
          Products: Products,
          TactonBomShortCode: shortCode
        });
      };

      var parentconfiguratorStartResponse = main.configuratorStartResponse;

      main.configuratorStartResponse = function (response) {
        parentconfiguratorStartResponse(response);

        if (main.currentStep.name == 'input_long_code_step' && main.configCode) {
          var changedObg = {
            changed: main.configCode,
            member: {
              name: "root_part_input_long_code_field"
            }
          };
          main.inputChanged(changedObg);
        }

        if (main.family) {
          $scope.$emit('event_NavigationSet', {
            data: 1
          });
        }

        ;
      };

      var parentconfiguratorCommitResponse = main.configuratorCommitResponse;

      main.configuratorCommitResponse = function (response) {
        parentconfiguratorCommitResponse(response);

        if (main.currentStep.name == 'input_long_code_step') {
          //If we're receiving a commit response and we're on the longCode step, it means we've updated the longcode from a saved config
          main.isPartRetrieval = true;
          $scope.$emit('event_IsValidPartRetrieval', main.configCode);
          main.family = main.configCode.substring(0, 4);
          $scope.$emit('event_NavigationChangeEvent', {
            data: {
              index: 5
            }
          });
        }

        main.updateTactonCookie(main.cookieName, main.configState, main.currentStep.name, main.cookieVersion);
      };
    });

    $scope.GetStationPartNumber = function (bomStation) {
      return bomStation.subItems.find(function (i) {
        return i.attributes.uniqueComponentId === "Valve";
      }).attributes.componentCode;
    }; //filters


    $scope.IsemProperties = function (property) {
      return property.name.startsWith('isem_part_type_field') || property.name.startsWith('pilot_supply_pressure_zone_blanking_part_multipressure_island_field') || property.name.startsWith('pilot_supply_pressure_zone_blanking_part_type_field');
    };

    $scope.StationProperties = function (property) {
      return !$scope.IsemProperties(property);
    };

    $scope.StationGroup = function (group) {
      return group.name === "stations_BOM_group_comp";
    };

    $scope.ValveGroup = function (group) {
      return !$scope.StationGroup(group);
    };

    $scope.IsemBom = function (item) {
      return item.attributes.uniqueComponentId === "Intermediate Supply/Exhaust Module" || item.attributes.uniqueComponentId === "Blanking Plug Kit";
    };

    $scope.StationBom = function (item) {
      return !$scope.IsemBom(item);
    };

    $scope.ShowIsem = function (items) {
      return items.some(function (i) {
        return $scope.IsemBom(i);
      });
    };

    $scope.raiseDiscardEvent = function () {
      main.deleteTactonCookie("tactonViCookie");
      main.deleteTactonCookie("viFamily");
      $.fancybox.close();
      location.reload();
    };
  }

  ; // Components

  app.component("tactonVi", {
    controller: ["$http", "$sce", "$scope", '$document', "$attrs", '$controller', "$compile", "$rootRouter", "$window", "$q", "tactonAjaxService", "tactonRootObject", "tactonBomRootObject", '$anchorScroll', tactonViController],
    bindings: {
      family: '@'
    },
    templateUrl: "/mkt/ln/js/app/templates/tacton-vi/mainController-template.html"
  });
})(); //# sourceURL=tactonvi.component.js