"use strict";

(function () {
  "use strict";

  function LibraryContentModelFactory(category) {
    function LibraryContentModel(categoryModels, data) {
      this.categories = categoryModels;
      this.useCarousel = data.UseCarousel;
      this.showCategoryPreview = data.ShowCategoryPreview;
      this.usePagination = data.UsePagination;
      this.itemsPerPage = data.ItemsPerPage || 10;
    }

    LibraryContentModel.build = function (data) {
      var flattenCategoryItems = function flattenCategoryItems(categories) {
        var allCategories = JSON.parse(JSON.stringify(categories));
        var items = [];
        allCategories.forEach(function (item) {
          items = items.concat(item.items);
        });
        return items;
      };

      if (!data) {
        return null;
      }

      var dataCategories = data.Categories;
      var categories = [];

      for (var i = 0; i < dataCategories.length; i++) {
        categories.push(category.build(dataCategories[i]));
      }

      var contentModel = new LibraryContentModel(categories, data);
      var totalItems = flattenCategoryItems(contentModel.categories).length;

      if (contentModel.usePagination) {
        var items = [];
        contentModel.categories.forEach(function (item) {
          items = items.concat(item.items);
        });
        items.forEach(function (item, index) {
          item.visible = index < contentModel.itemsPerPage;
        });
      } else {
        contentModel.itemsPerPage = totalItems;
      }

      return contentModel;
    };

    return LibraryContentModel;
  }

  function CategoryModelFactory(item) {
    function CategoryModel(itemModels, data) {
      this.name = data.Name;
      this.items = itemModels;
      this.sortOrder = undefined;
      this.visible = true;
      this.previewDescription = data.PreviewDescription;
      this.collapsablePanel = data.CollapsablePanel;
      this.id = data.Id;
    }

    CategoryModel.build = function (data) {
      if (!data) {
        return null;
      }

      var dataChildren = data.Children;
      var items = [];

      for (var i = 0; i < dataChildren.length; i++) {
        items.push(item.build(dataChildren[i]));
      }

      return new CategoryModel(items, data);
    };

    return CategoryModel;
  }

  function ItemModelFactory($sce) {
    function ItemModel(data) {
      this.title = data.Title;
      this.subTitle = data.SubTitle;
      this.link = data.Link;
      this.target = data.Target;
      this.imageUrl = data.ImageUrl;
      this.date = data.Date;
      this.runtime = data.Runtime;
      this.html = $sce.trustAsHtml(data.Html);
      this.itemType = data.ItemType;
      this.publishedDate = data.PublishedDate;
      this.tags = data.Tags;
      this.visible = true;
      this.showPlayButton = data.ShowPlayButton;
    }

    ItemModel.build = function (data) {
      return new ItemModel(data);
    };

    return ItemModel;
  }

  angular.module('norgrenApp').factory("LibraryContentModelFactory", ["CategoryModelFactory", LibraryContentModelFactory]);
  angular.module('norgrenApp').factory("CategoryModelFactory", ["ItemModelFactory", CategoryModelFactory]);
  angular.module('norgrenApp').factory("ItemModelFactory", ["$sce", ItemModelFactory]);
})();