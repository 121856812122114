"use strict";

(function () {
  function libraryHeader($scope, $window) {
    var main = $scope.$ctrl;
    $scope.isSticky = false;
    var header = document.getElementById("library-header");
    var pageHeader = document.getElementsByTagName('header')[0];
    var divAnchor = document.getElementById("Header-anchor");
    var divStopAnchor = document.getElementById("header-stop-anchor");
    var anchorPos = findPos(divAnchor);
    var divStopPos = 0;
    if (divStopAnchor) divStopPos = findPos(divStopAnchor);
    angular.element($window).bind("scroll", function () {
      var windowScrollTop = this.pageYOffset;
      divStopAnchor = document.getElementById("header-stop-anchor");
      divStopPos = findPos(divStopAnchor);
      anchorPos = findPos(divAnchor);

      if (windowScrollTop > anchorPos && windowScrollTop < divStopPos) {
        header.style.position = "fixed";
        header.style.top = pageHeader.offsetHeight + "px";
        var headerHeight = header.offsetHeight + 30;
        divAnchor.style.paddingTop = headerHeight + "px";
        $scope.$apply($scope.isSticky = true);
      } else if (windowScrollTop <= anchorPos) {
        header.style.position = "relative";
        header.style.top = "";
        divAnchor.style.paddingTop = 0;
        $scope.$apply($scope.isSticky = true);
      } else if (windowScrollTop > divStopPos) {
        header.style.position = "absolute";
        header.style.top = divStopPos.offsetHeight + "px";
      }
    });
  }

  function findPos(pageElement) {
    var curtop = 0;

    if (pageElement.offsetParent) {
      do {
        curtop += pageElement.offsetTop;
      } while (pageElement = pageElement.offsetParent);

      return [curtop];
    }
  }

  angular.module('norgrenApp').component('libraryHeader', {
    templateUrl: '/mkt/ln/js/app/templates/ContentItemsLister/library-header-template.html',
    controller: libraryHeader,
    bindings: {
      translations: '=',
      tags: '=',
      data: '=',
      sortByOptions: '=',
      searchCallback: "<",
      selectTagsCallback: "<",
      usePagination: "="
    }
  });
})();