"use strict";

(function () {
  "use strict";

  angular.module("norgrenApp").controller("BasePaginationController", ["$scope", "$controller", "$attrs", function ($scope, $controller, $attrs) {
    angular.extend(this, $controller('BaseDistributorController', {
      $scope: $scope
    }));
    var main = $scope.$ctrl;
    main.currentPage = 1;
    main.itemsPerPage = parseInt($attrs.itemsperpage);
    main.totalItems = parseInt($attrs.totalitems);
    main.totalPages = parseInt($attrs.totalpages);
    main.showPager = $attrs.showpager === 'true';

    main.showPager = function () {
      return main.showPager ? '' : ' hidden';
    };

    main.isPrevDisabled = function () {
      return main.prevDisabled = main.currentPage === 1;
    };

    main.isNextDisabled = function () {
      return main.nextDisabled = main.currentPage === main.totalPages;
    };

    main.nextPage = function () {
      var next = main.currentPage;

      if (next < main.totalPages) {
        next = main.currentPage + 1;
      }

      main.getPage(next);
    };

    main.prevPage = function () {
      var prev = main.currentPage;

      if (prev > 1) {
        prev = main.currentPage - 1;
      }

      main.getPage(prev);
    };
  }]);
})();